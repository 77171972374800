import * as React from 'react';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Person4Icon from '@mui/icons-material/Person4';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import WalletIcon from '@mui/icons-material/Wallet';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import HistoryIcon from '@mui/icons-material/History';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SearchIcon from '@mui/icons-material/Search';
import AutoPopularGames from '../components/Home/AutoPopularGames';

import { addSearchHistory, removeSearchHistory } from '../redux/reducers/PageReducer';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Divider, Collapse, Typography, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, InputAdornment, SwipeableDrawer, Box } from '@mui/material';
import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import axiosInstance from '../redux/axios';
import { logOutUser } from '../redux/reducers/UserReducer';
function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}


export default function SwipableaDrawer({mobileSwipable, setMobileSwipable, toggleDrawer, setShowWallet, setShowSetWallet, setShowVIP, setShowVault }){
    const {searchHistory} = useSelector(state => state.page);
    const {user} = useSelector(state => state.user);

    const [expanded, setExpanded] = useState('');
    const [primeSelection, setPrimeSelection] = useState('');
    const [focusInput, setFocusInput] = useState(false);
    const [search, setSearch] = useState('');
    const [searchOptions, setSearchOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [secondTime, setSecondTime] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const history = useLocation(); 

    useEffect(() => {
        if (!focusInput) {
            setOptions([]);
        }
        if(focusInput){
            (async () => {
                await sleep(1e3); // For demo purposes.
        
                setOptions([...topGames]);
            })();
        }
    }, [focusInput]);

    const changePrime = (val) => {
        if(val !== 'support'){
          setPrimeSelection(val)
          setMobileSwipable(false)
          if(val === 'favourites' || val === 'recent'){
              navigate('/play/'+val)
          }
          else if(val === 'slots' || val === 'new-releases'){
              navigate('/play/group/'+val)
          }
          else{
              navigate(val.toLowerCase().replaceAll(' ', '-'))
          }
        }
    }
    const changeOther = (text) => {
      if(text ==='Wallet'){
        if(user?.is_user_verified){
          setShowWallet(true)
        }else{
          setShowSetWallet(true)
        }
      }
      if(text === "Rank"){
        setShowVIP(true)
      }
      if(text === 'Cashier'){
        setShowVault(true)
      }
      if(text === 'Logout'){
        logOut()
      }
      setMobileSwipable(false)
      document.body.style.removeProperty('overflow')
    }

    const toggleProfile = () => {
      if(expanded === 'profile'){
        setExpanded('')
      }else{
        setExpanded('profile')
      }
    }
    const openChatWidget = () => {
      setMobileSwipable(false)
      if(window.fcWidget){
          if(!window.fcWidget?.isOpen()){
              window.fcWidget.open()
          }else{
              window.fcWidget.close()
          }
      }
    }

    const logOut = async () => {
      try{
        await axiosInstance.get('auth/logout');
        dispatch(logOutUser())
        
        navigate('/')
      }catch{
        dispatch(logOutUser())
        navigate('/')
      }
    }

    const changeInputSearch = (e) => {
      if(e.target.value === ''){
        setFocusInput(false)
      }else{
        setFocusInput(true)
      }
      
      if(e.target.value.length >= 3){
        let filtered = [...topGames];
        filtered = filtered.filter(x => x.title.toLowerCase().includes(e.target.value.toLowerCase()))
        setSearchOptions(filtered)
        if(filtered.length > 0){
            let index = searchHistory?.findIndex(x => x.name.toLowerCase().includes(e.target.value.toLowerCase()));
            if(index === -1){
                dispatch(addSearchHistory({id: searchHistory.length, name: filtered[0].title}))
            }
        }
      }
      setSearch(e.target.value)
    }

    const handleDeleteChip = (name) => {
      dispatch(removeSearchHistory(name))
    }


    useEffect(() => {
      let itemPrime = history?.pathname?.split('/')[1]
      
      if(itemPrime){
        if(itemPrime === 'play'){
          let itemP2 = history?.pathname?.split('/')[2]
          if(itemP2){
            if(itemP2 === 'group'){
              let item3 = history?.pathname?.split('/')[3]
                if(item3){
                  setPrimeSelection(item3)
                }
            }else{
              setPrimeSelection(itemP2)
            }
            
          }else{
            setPrimeSelection('')
          }
        }else{
          setPrimeSelection(itemPrime)
        }
      }
    }, [history])

    const list = (anchor: Anchor) => (
        <Box
          sx = {{width: '100%', paddingTop: '1rem'}}
          role="presentation"
        >
          <div>
            <TextField
            onFocus={() => setFocusInput(true)}
            value={search}
            onChange={(e) => changeInputSearch(e)}
            className="search_mobile_menu"
            id="search_mobile_menu"
            sx={{ m: 1, width: '96%' }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
              endAdornment: 
              <InputAdornment position="end">
                {(search !== '' || focusInput) &&
                  <CloseIcon onClick={() => {setSearch(''); setFocusInput(false)}}/>
                }
              </InputAdornment>
            }}
            placeholder="Search for games"
            />
          </div>
          {!focusInput &&
          <>
            {user &&
            <List className="list_decor">
              {['Favourites', 'Recent'].map((text, index) => (
                <ListItem 
                key={text}
                selected={primeSelection?.toString().toLowerCase() === text.toLowerCase()}
                onClick={() => changePrime(text.toLowerCase())} 
                disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <StarBorderIcon /> : <HistoryIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>}
            <List className="list_decor">
              {['Slots', 'New Releases'].map((text, index) => (
                <ListItem 
                key={text} 
                disablePadding
                selected={primeSelection?.toString().toLowerCase() === text.toLowerCase().replaceAll(' ', '-')}
                onClick={() => changePrime(text.toLowerCase().replaceAll(' ', '-'))} 
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <AdfScannerIcon /> : <RocketLaunchIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            {user &&
            <List className="list_decor">
              <ListItem onClick={() => toggleProfile()} disablePadding className={expanded === 'profile' ? 'sub_extended' : ''} sx={{ display: 'block' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <Person4Icon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                    
                    <ListItemIcon style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              <Collapse className={expanded === 'profile' ? 'sub_extended_collapse' : ''} in={expanded === 'profile'} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {['Wallet', 'Cashier', 'Rank','Transactions', 'Settings', 'Logout'].map((text, index) => (
                    <Fragment key={index}>
                        <ListItemButton
                        selected={primeSelection?.toString().toLowerCase() === text.toLowerCase()}
                        onClick={() => index === 3 || index === 4 ? changePrime(text) : changeOther(text)}
                        sx={{ pl: 4 }}>
                            <ListItemIcon>
                                {index === 0 ? <WalletIcon/> : index === 1 ? <CurrencyExchangeIcon/> : index === 2 ? <MilitaryTechIcon/> : index === 3 ? <ReceiptLongIcon/> : index === 4 ? <SettingsIcon /> : <LogoutIcon/> }
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                        {index === 5 && <Divider />}
                    </Fragment>
                ))}
                </List>
              </Collapse>
            </List>}


            <List className="list_decor">
              {['Rank Club', 'Support'].map((text, index) => (
                <ListItem 
                key={text} 
                disablePadding 
                selected={primeSelection?.toString().toLowerCase() === text.toLowerCase().replaceAll(' ', '-')}
                onClick={() => { index === 0 ? changePrime(text.toLowerCase().replaceAll(' ', '-')) : openChatWidget() }} >
                  <ListItemButton>
                    <ListItemIcon>
                      {index === 1 ? <MilitaryTechIcon /> : <SupportAgentIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
          }
          {focusInput &&
            <>
              {search.length < 3 &&
                <>
                    <div className="display_search_autocomplete">
                        Search requires at least 3 characters.
                    </div>
                    
                    {searchHistory.length > 0 &&
                        <>
                            <Typography className="shadowed_text title_autocomplete" sx={{paddingTop: '1rem', paddingLeft: '1rem'}}>Recent Searches</Typography>
                            <div className="chip_container" style={{paddingTop: '1rem', paddingLeft: '1rem'}}>
                                {searchHistory && searchHistory.map(s => 
                                    <Chip key={s.id} label={s.name} onDelete={() => handleDeleteChip(s.name)} />
                                )}
                            </div>
                        </>
                    }
                    
                </>
                }
                 {search.length >= 3 &&
                    <>
                        {searchOptions.length === 0 &&
                        <>
                            <div className="display_search_autocomplete">
                            No results found.
                            </div>
                            <AutoPopularGames options={options}/>
                        </>
                        }
                        {
                            searchOptions.length > 0 &&

                            <AutoPopularGames options={searchOptions} search={true}/>
                        }
                    </>
                    }
            </>
          }
        </Box>
      );
    //toggleDrawer('bottom', true)}
    return(
        <SwipeableDrawer
            className="swipable_drower"
            anchor='bottom'
            disableSwipeToOpen={true}
            disableDiscovery={true}
            open={mobileSwipable}
            onClose={toggleDrawer('bottom', false)}
            onOpen={toggleDrawer('bottom', true)}
            ModalProps={{
              keepMounted: true,
            }}
        >
            {list('bottom')}
        </SwipeableDrawer>
    )
}

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topGames = [
  { id: 1,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
  { id: 2,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
  { id: 3,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
  { id: 4,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
  { id: 5,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
  { id: 6,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
  { id: 7,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
  { id: 8,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
  { id: 9,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
  { id: 10,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
  { id: 11,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
  { id: 12,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
  
];