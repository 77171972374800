import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   searchHistory: [],
   countries: []
};

const PageSlice = createSlice({
    name: "page",
    initialState: initialState,
    reducers: {
        addSearchHistory: (state, action) => {
            state.searchHistory.push(action.payload);
        },
        removeSearchHistory: (state, action) => {
            let index = state.searchHistory.findIndex(x => x.name === action.payload);
            if(index !== -1){
                state.searchHistory.splice(index, 1)
            }
        },
        setCountries: (state, action) => {
            state.countries = action.payload
        },
        removeCountries: (state, action) => {
            state.countries = []
        }
    }
});

export const {
    addSearchHistory, removeSearchHistory, 
    setCountries, removeCountries
} = PageSlice.actions;

export default PageSlice.reducer;