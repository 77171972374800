import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSubmitedWallet: false,
  firstStepCompleted: false,
  userDetails: {
    firstName: '',
    lastName: '',
    country: '',
    day: '',
    month: '',
    year: '',
    address: '',
    city: '',
    postalCode: '', 
    occupation: ''
 }
};

const WalletSlice = createSlice({
    name: "wallet",
    initialState: initialState,
    reducers: {
        setFirstStepCompleted: (state, action) => {
            state.firstStepCompleted = action.payload.firstStepCompleted;
            state.userDetails = action.payload?.userDetails;
        },
        setWalletSubmitted: (state, action) => {
            state.isSubmitedWallet = action.payload;
        }
    }
});

export const {
    setFirstStepCompleted,
    setWalletSubmitted
} = WalletSlice.actions;

export default WalletSlice.reducer;