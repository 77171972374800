import { Chip, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Form} from 'react-bootstrap';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCountries } from '../../redux/reducers/PageReducer';
import { setUser }from '../../redux/reducers/UserReducer';
import { Switch, FormHelperText, Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import WalletDetails from '../Profile/Wallet_Details';
import WalletID from '../Profile/Wallet_ID';
import axiosInstance from '../../redux/axios';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
}));

function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+$/;
    return emailPattern.test(email);
}

const validatePhoneNumber = (phone) => {
    const regex = /^\+\d{1,3}\d{7,14}$/;
    return regex.test(phone);
};

const ranks = [
    {id: 0, name: 'Step I'},
    {id: 1, name: 'Step II'},
]

export default function SettingsDetails({selected}){
    const {countries} = useSelector(state => state.page);
    const {user} = useSelector(state => state.user);
    const dispatch = useDispatch();
    //general
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [successEmail, setSuccessEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [errorPhone, setErrorPhone]= useState('');
    const [successPhone, setSuccessPhone] = useState('');
    const [countryCode, setCountryCode] = useState(countries ? (countries[0]?.name + ' +' + countries[0]?.callingCodes) : '');

    useEffect(() => {
        setErrorEmail('')
        setSuccessEmail('')
    }, [email])

    useEffect(() => {
        setErrorPhone('')
        setSuccessPhone('')
    }, [phone_number, countryCode])

    useEffect(() => {
        setErrorEmail('')
    }, [successEmail])

    useEffect(() => {
        setErrorPhone('')
    }, [successPhone])

    const getCountries = useCallback(
        async () => {
          try{
              const response = await fetch("https://restcountries.com/v2/all");
              const c = await response.json();
              dispatch(setCountries(c));
          }catch{}
        },
        [dispatch],
      )
    useEffect(() => {
    if(!countries?.length){
        getCountries()
    }
    }, [countries, getCountries])

    useEffect(() => {
        setEmail(user?.email)
        if(user?.phone_number){
            const prefix = user?.phone_number;

            const foundItem = countries?.find(item => item.name.toString().startsWith(prefix?.split(' ')[0].toString()));
         
            if(foundItem){
                const prefix = foundItem?.name + ' +' + foundItem?.callingCodes
                setCountryCode(prefix)
                const rest = user?.phone_number?.replace(new RegExp(prefix.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'), '');
                
                setPhoneNumber(rest)
            }
        }
    }, [])

    //preferences
    const [ghostMode, setGhostMode] = useState(true);
    const [hideStats, setHideStats] = useState(false);
    const [marketing, setMarketing] = useState(true);

    //verify 
    const [expanded, changeExptended] = useState('Step I');
    
    const changeEmail = async () => {
        if(validateEmail(email)){
            try{
                const result = await axiosInstance.put('users/'+user?.id+'?email='+email);
           
                if(result?.data?.data){
                    dispatch(setUser(result?.data?.data))
                    setEmail(result?.data?.data?.email)
                    setSuccessEmail('Email changed successfully')

                    setTimeout(() => {
                        setSuccessEmail('')
                    }, 3000)
                }
            }catch(e){
                if(e.response?.data?.message){
                    setErrorEmail(e.response?.data?.message);
                }
                if(e.response?.data?.error){
                    setErrorEmail(e.response?.data?.error);
                }
            }
        }else{
            setErrorEmail('Invalid email format')
        }
    }

    const changePhoneNumber = async () => {
        if(phone_number !== '' && countryCode !== ''){
            const parts = countryCode.split(' ');
            const prefix = parts[parts.length - 1];
            if(validatePhoneNumber(prefix + phone_number)){
                try{
                    const result = await axiosInstance.put('users/'+user?.id+'?phone_number='+ encodeURIComponent(countryCode + phone_number));

                    if(result?.data?.data){
                        dispatch(setUser(result?.data?.data))
                        setSuccessPhone('Phone number updated successfully')
    
                        setTimeout(() => {
                            setSuccessPhone('')
                        }, 3000)
                    }
                }catch(e){
                    if(e.response?.data?.message){
                        setErrorPhone(e.response?.data?.message);
                    }
                    if(e.response?.data?.error){
                        setErrorPhone(e.response?.data?.error);
                    }
                }
            }else{
                setErrorPhone('Invalid phone number');
                return;
            }
        }else{
            setErrorPhone('Fill in the form');
        }
    }

    return(
        <div className="transaction_sidemenu_table">

            {/* GENERAL */
            selected === 'General' &&
            <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                <div className="card_settings">
                    <div className="card_main_settings">
                        <h5>
                            Email 
                            {user?.is_email_verified && <Chip size="small" className="chip_settings ms-2 mb-1 ps-1" icon={<CheckIcon className="pb-1" />} color="success" label="Verified" />}
                            {!user?.is_email_verified && <Chip size="small" className="chip_settings ms-2 mb-1 ps-1" icon={<ErrorOutlineIcon className="pb-1" />} color="warning" label="Verification Required" />}
                        </h5>
                        <Form data-bs-theme="dark" noValidate>
                            <Form.Group className="mt-4" controlId="setting.email">
                                <Form.Label>Email <span className="form_required">*</span></Form.Label>
                                <Form.Control  
                                    className="card_main_settings_input"
                                    style={{width: '60%'}} 
                                    required
                                    type="email" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                        {errorEmail && <div className="error_p pt-2">{errorEmail}</div>}
                        {successEmail && <div className="success_p pt-2">{successEmail}</div>}
                    </div>
                    <div className="card_footer_settings">
                        <Button className="text_btn">Resend Email</Button>
                        <Button className="contained_btn" onClick={() => changeEmail()} disabled={email === user?.email || email === ''} variant="contained">Change</Button>
                    </div>
                </div>

                <div className="card_settings">
                    <div className="card_main_settings">
                        <h5>
                            Phone Number 
                            {user?.phone_number && <Chip size="small" className="chip_settings ms-2 mb-1 ps-1" icon={<CheckIcon className="pb-1" />} color="success" label="Saved" />}
                            {!user?.phone_number && <Chip size="small" className="chip_settings ms-2 mb-1" label="Not Required" />}
                        </h5>
                        <Form data-bs-theme="dark" noValidate>
                            <Form.Group className="mt-4" controlId="setting.country_code">
                                <Form.Label>Country Code <span className="form_required">*</span></Form.Label>
                                    <Form.Select 
                                    className="card_main_settings_input"
                                    style={{width: '60%'}} 
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                    aria-label="CountryCode"
                                    >
                                        {countries && countries.length > 0 &&
                                        countries.map(c => 
                                            <option value={c.name + ' +' + c.callingCodes} key={c.name}>{c.name + ' +' + c.callingCodes}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        This field is required.
                                    </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mt-4" controlId="setting.phone_number">
                                <Form.Label>Phone Number <span className="form_required">*</span></Form.Label>
                                <Form.Control  
                                    className="card_main_settings_input"
                                    style={{width: '60%'}} 
                                    required
                                    type="text" 
                                    value={phone_number}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>

                        {errorPhone && <div className="error_p pt-2">{errorPhone}</div>}
                        {successPhone && <div className="success_p pt-2">{successPhone}</div>}
                    </div>
                    <div className="card_footer_settings">
                       <Button className="contained_btn" onClick={() => {changePhoneNumber()}} variant="contained">Submit</Button>
                    </div>
                </div>
            </div>
            }

            {/* Preferences */
            selected === 'Preferences' &&
            <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                <div className="card_settings">
                    <div className="card_main_settings">
                        <h5>
                            Privacy <br/>
                            <span>These settings allow you to be completely anonymous from the rest of the players.</span>
                        </h5>
                        <Form data-bs-theme="dark" noValidate>
                            <div className="switch_settings pb-4 pt-3">
                                <FormControlLabel
                                    checked={ghostMode}
                                    onChange={(e) => setGhostMode(e.target.checked)}
                                    control={
                                        <IOSSwitch 
                                        sx={{ m: 1 }} 
                                        />
                                    }
                                />
                                <div>
                                    <div className="switch_settings_label">Enable Ghost Mode</div>
                                    <FormHelperText>Your username will not appear in public bet feed and bet preview</FormHelperText>
                                </div>  
                            </div>
                            <div className="switch_settings">
                                <FormControlLabel
                                    checked={hideStats}
                                    onChange={(e) => setHideStats(e.target.checked)}
                                    control={
                                        <IOSSwitch 
                                        sx={{ m: 1 }} 
                                        />
                                    }
                                />
                                <div>
                                    <div className="switch_settings_label">Hide your statistics</div>
                                    <FormHelperText>Other users won't be able to view your wins, losses and wagered statistics</FormHelperText>
                                </div>    
                            </div>    
                        </Form>
                    </div>
                    <div className="card_footer_settings">
                        <Button className="contained_btn" variant="contained">Save</Button>
                    </div>
                </div>

                <div className="card_settings">
                    <div className="card_main_settings">
                        <h5>
                           Marketing
                        </h5>
                        <Form data-bs-theme="dark" noValidate>
                            <div className="switch_settings pb-3 pt-3">
                                <FormControlLabel
                                    checked={marketing}
                                    onChange={(e) => setMarketing(e.target.checked)}
                                    control={
                                        <IOSSwitch 
                                        sx={{ m: 1 }} 
                                        />
                                    }
                                />
                                <div>
                                    <div className="switch_settings_label">Receive Email Marketing</div>
                                    <FormHelperText>Subscription status for marketing emails or offers</FormHelperText>
                                </div>  
                            </div>
                        </Form>
                    </div>
                    <div className="card_footer_settings">
                       <Button className="contained_btn" variant="contained">Save</Button>
                    </div>
                </div>
            </div>
            }

            {/*Ignored Users */
            selected === 'Ignored Users' &&
            <div style={{display: 'flex', flexDirection: 'column', gap: '2rem', flex: '1'}}>
                <div className="main_container_transactions">
                    <div>
                        <div><PublicOffIcon className="icon_transactions"/></div>
                        <div>No ignored users to show</div>
                    </div>
                </div>
            </div>
            }

            {/*Verify */
            selected === 'Verify' &&
            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', flex: '1'}}>
               {ranks.map(r => 
                <Accordion className="accordion_rank" key={r.id} expanded={expanded === r.name} onChange={() => {r.name === expanded ? changeExptended(-1) : changeExptended(r.name)}}>
                        <AccordionSummary
                            className="accordion_rank_summary"
                            expandIcon={<ChevronLeftIcon />}
                            aria-controls={"panel"+r.id+"verify-content"}
                            id={"panel"+r.id+"verify-content"}
                        >
                            <Typography sx={{ width: '100%', flexShrink: 0, display: 'flex', alignItems: 'center' }}>
                                <span className="pt-1">{r.name}</span> 
                                {r.id === 0 && user?.is_step_one_completed && <Chip size="small" className="chip_settings ms-2 mb-1 ps-1" icon={<CheckIcon className="pb-1" />} color="success" label="Verified" />}
                                {r.id === 1 && user?.is_step_two_completed && <Chip size="small" className="chip_settings ms-2 mb-1 ps-1" icon={<CheckIcon className="pb-1" />} color="success" label="Verified" />}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="rank_acc_details">
                            {r.id === 0 &&
                                <WalletDetails settings={true} handleStep={() => {}} userDetails={user} />
                            }
                            {r.id === 1 && 
                                <WalletID settings={true} />
                            }
                        </AccordionDetails>
                </Accordion>
                )}
            </div>
            }

            {/*Offers */
            selected === 'Offers' &&
            <div style={{display: 'flex', flexDirection: 'column', gap: '2rem', flex: '1'}}>
                <div className="card_settings">
                    <div className="card_main_settings">
                        <h5>
                            Welcome Offer <br/>
                            <span>Welcome offers are only valid for first 24 hours after signing up.</span>
                        </h5>
                        <Form data-bs-theme="dark" noValidate>
                            <Form.Group className="mt-4" controlId="setting.code">
                                <Form.Label>Code <span className="form_required">*</span></Form.Label>
                                <Form.Control  
                                    className="card_main_settings_input"  
                                    style={{width: '60%'}} 
                                    required
                                    type="text" 
                                    readOnly 
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="card_footer_settings">
                        <Button className="contained_btn" disabled variant="contained">Submit</Button>
                    </div>
                </div>

                <div className="card_settings">
                    <div className="card_main_settings">
                        <h5>
                            Redeem Bonus Drop <br/>
                            <span>Find bonus drop codes on our social media's such as Twitter & Telegram</span>
                        </h5>
                        <Form data-bs-theme="dark" noValidate>
                            <Form.Group className="mt-4" controlId="setting.code">
                                <Form.Label>Code <span className="form_required">*</span></Form.Label>
                                <Form.Control    
                                    className="card_main_settings_input"
                                    style={{width: '60%'}} 
                                    required
                                    type="text" 
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="card_footer_settings">
                        <Button className="contained_btn" disabled variant="contained">Submit</Button>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}