import { Avatar } from '@mui/material';

export default function CashierCreditsBoard(){
    return(
        <div className="display_flex_center">
            <div style={{gap: '7rem'}} className="display_spacebetween full_width darker_bg">
                <div className="display_spacebetween">
                    <Avatar alt="User Name" src="" />
                    <div className="ps-2">Username</div>
                </div>

                <div>
                    0
                    Credits
                </div>
            </div>
        </div>
    )
}