import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

export default function Licenses() {

    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div> <SettingsIcon className="faded_icon"/> Licenses </div>
                    <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Play
                        </Link> 
                        <Typography color="text.primary">Licenses</Typography>
                    </Breadcrumbs>
                </Container>
            </div>

            <Container className="pt-5">
               
            </Container>
        </div>
    )
}