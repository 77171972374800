import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { store } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from "react-redux";

import "./assets/css/scss/_variables.scss";
import "./assets/css/responsive/responsive_home.css"
import ScrollToTop from './components/ScrollToTop';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#C174E8',
      main: '#923FB5',
      dark: '#610085',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7961FF',
      main: '#6F36F5',
      dark: '#3200BA',
      contrastText: '#000',
    },
  },
});
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
				<PersistGate persistor={persistor}>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <BrowserRouter>
              <ScrollToTop/>
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
