import {Container} from 'react-bootstrap';
import "../assets/css/Home.css";
import AutoCompleteSearch from '../components/Home/AutoCompleteSearch';
import {Grid} from '@mui/material';
import HeaderPicks from '../components/Home/HeaderPicks';
import Providers from '../components/Home/Providers';
import OtherGames from '../components/Home/OtherGames';
import StackScroll from '../components/Home/StackScroll';
import {useNavigate} from 'react-router-dom';

const picks = [
    {id: 0, img: '/static/test/pick1.webp', playing: '4,422'},
    {id: 1, img: '/static/test/pick2.webp', playing: '2,091'},
    {id: 2, img: '/static/test/pick3.webp', playing: '3,048'},
    {id: 3, img: '/static/test/pick4.jpg', playing: '545'},
    {id: 4, img: '/static/test/pick5.webp', playing: '10,022'},
    {id: 5, img: '/static/test/pick6.webp', playing: '1,310'},
    {id: 6, img: '/static/test/pick7.webp', playing: '1,131'},
    {id: 7, img: '/static/test/pick8.webp', playing: '148'},
    {id: 8, img: '/static/test/pick10.webp', playing: '202'},
    {id: 9, img: '/static/test/pick11.png', playing: '202,322'},
]
export default function Home(){
    const navigate = useNavigate();

    return (
        <div className="main_page">
            <Container className="container_home_search">
               
                <AutoCompleteSearch />

            </Container>

            <Container style={{paddingTop: '2rem'}}>
                <HeaderPicks text="Our picks"/>

                <Grid mt={3} container justifyContent="center" spacing={1}>
                    <Grid className="side_home_picks" container item spacing={1} xs={12} sm={4} md={4}>
                        {picks && picks.slice(0,4).map(p => 
                            <Grid onClick={() => navigate('/play/slots/games/'+p.id)} className="glid_inside_go" key={p.id} item xs={3} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                <div style={{flex: 1}}>
                                    <img className="item_picks" src={p.img} alt="cover" />
                                </div>
                                <div className="online_grid"><div className="green_online"></div>{p.playing} <span className="shadowed_text ms-1"> playing</span></div>
                            </Grid>
                        )}
                    </Grid>
                    <Grid className="side_home_picks" item xs={12} sm={4} md={4}>
                        {picks && picks.slice(4,5).map(p => 
                            <Grid onClick={() => navigate('/play/slots/games/'+p.id)} style={{height: '100%'}} className="glid_inside_go" key={p.id} item xs={12}>
                                <div style={{flex: 1}}>
                                    <img className="item_picks" src={p.img} alt="cover" />
                                </div>
                                <div className="online_grid"><div className="green_online"></div>{p.playing} <span className="shadowed_text ms-1"> playing</span></div>
                            </Grid>
                        )}
                    </Grid>
                    <Grid className="side_home_picks" container item spacing={1} xs={12} sm={4} md={4}>
                        {picks && picks.slice(5,9).map(p => 
                            <Grid onClick={() => navigate('/play/slots/games/'+p.id)} className="glid_inside_go" key={p.id} item xs={3} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                <div style={{flex: 1}}>
                                    <img className="item_picks" src={p.img} alt="cover" />
                                </div>
                                <div className="online_grid"><div className="green_online"></div>{p.playing} <span className="shadowed_text ms-1"> playing</span></div>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

            </Container>

            <Container style={{paddingTop: '4rem'}}>
                <HeaderPicks text="Game of the Week"/>

                <Grid onClick={() => navigate('/play/slots/games/'+picks[9]?.id)} mt={2} style={{height: '100%'}} className="glid_inside_go" item xs={12}>
                    <div style={{flex: 1}}>
                        <img className="item_picks" src={picks[9]?.img} alt="cover" />
                    </div>
                    <div className="online_grid"><div className="green_online"></div>{picks[9]?.playing} <span className="shadowed_text ms-1"> playing</span></div>
                </Grid>
            </Container>
            
            <Container className="overflow_containers" style={{paddingTop: '4rem'}}>
                 <Providers />
            </Container>
            
            <Container style={{paddingTop: '4rem'}}>
                <OtherGames text="New Releases" />
            </Container>
             
            <Container style={{paddingTop: '4rem', paddingBottom: '8rem'}}>
                <StackScroll text="Popular"/>
            </Container>
        </div>
    )
}

