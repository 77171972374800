
import './assets/css/App.css';
import { createBrowserHistory } from "history";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from './pages/Home';
import NavBar from './layouts/NavBar';
import SideDrawer from './layouts/SideDrawer';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {useState} from  'react';

import MuiAppBar from '@mui/material/AppBar';
import SimpleBottomNavigation from './layouts/BottomNav';
import Footer from './layouts/Footer';
import Favourite from './pages/Play/Favourite';
import Recent from './pages/Play/Recent';
import WalletSetUp from './components/Modals/WalletSetUp';
import Wallet from './components/Modals/Wallet';
import RankModal from './components/Modals/RankModal';
import CashierModal from './components/Modals/Cashier';
import Transactions from './pages/Transactions';
import Settings from './pages/Settings';
import RankClub from './pages/RankClub';
import SwipableaDrawer from './layouts/SwipableDrawer';
import Policies from './pages/Policies';
import Licenses from './pages/Licenses';
import GameDisplay from './pages/GameDisplay';
import ProvidersCollection from './pages/Play/Providers/ProvidersCollection';
import TagsCollection from './pages/Play/Tags/TagsCollection';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
    }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      }),
  }),
}));

function App() {
  const history = createBrowserHistory()
  const [open, setOpen] = useState(false);
  const [mobileSwipable, setMobileSwipable] = useState(false);

  //modals
  const [showSetWallet, setShowSetWallet] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [showVIP, setShowVIP] = useState(false);
  const [showVault, setShowVault] = useState(false);

  const hideAllModals = () => {
    setShowSetWallet(false)
    setShowWallet(false)
    setShowVIP(false)
  }

  const toggleSwipableDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event).key === 'Tab' ||
        (event).key === 'Shift')
    ) {
      return;
    }

    setMobileSwipable(open);
  };

  return (
    <>
      <div className="App">
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <SideDrawer 
          Drawer={Drawer} 
          DrawerHeader={DrawerHeader} 
          open={open} 
          setOpen={setOpen} 
          setShowSetWallet={setShowSetWallet} 
          setShowWallet={setShowWallet} 
          setShowVIP={setShowVIP}
          setShowVault={setShowVault} />
          <Box className="main_root" component="main" sx={{ flexGrow: 1, p: 3 }}>
            <NavBar 
            AppBar={AppBar} 
            open={open} 
            setOpen={setOpen} 
            setShowSetWallet={setShowSetWallet} 
            setShowWallet={setShowWallet} 
            setShowVIP={setShowVIP}
            setShowVault={setShowVault}
            />
            <Routes history={history}>
                <Route
                  path='/*'
                  element={
                    <Navigate to="/play"/>
                  } />
                  <Route
                    path='/play/*'
                    element={
                      <Routes>
                         <Route path="/*" element={<Navigate to='/play'/>} />
                        <Route path="/" element={<Home/>} />
                        <Route path="/favourites" element={<Favourite/>} />
                        <Route path="/recent" element={<Recent/>} />
                        <Route path="/group/:tag_name" element={<TagsCollection/>} />
                        <Route path="/slots/*" element={
                          <Routes>
                            <Route path="/*" element={<Navigate to='/play'/>} />
                            <Route path="/games/:game_name?" element={<GameDisplay/>} />
                          </Routes>
                        } />
                       <Route path="/providers/*" element={
                          <Routes>
                            <Route path="/*" element={<Navigate to='/play/providers'/>} />
                            <Route path="/" element={<ProvidersCollection/>} />
                          </Routes>
                        } />
                      </Routes>
                  } />   
                  <Route 
                    path='/transactions/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/transactions'/>} />
                        <Route path="/" element={<Transactions/>} />
                        <Route path="/deposits" element={<Transactions/>} />
                        <Route path="/withdrawals" element={<Transactions/>} />
                        <Route path="/other" element={<Transactions/>} />
                      </Routes>
                    }
                  />
                  <Route 
                    path='/settings/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/settings'/>} />
                        <Route path="/" element={<Settings/>} />
                        <Route path="/general" element={<Settings/>} />
                        <Route path="/security" element={<Settings/>} />
                        <Route path="/preferences" element={<Settings/>} />
                        <Route path="/ignored-users" element={<Settings/>} />
                        <Route path="/verify" element={<Settings/>} />
                        <Route path="/offers" element={<Settings/>} />
                      </Routes>
                    }
                  />
                  <Route 
                    path='/rank-club/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/rank-club'/>} />
                        <Route path="/" element={<RankClub/>} />
                      </Routes>
                    }
                  />
                  <Route 
                    path='/policies/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/policies'/>} />
                        <Route path="/" element={<Policies/>} />
                        <Route path="/terms-of-service" element={<Policies/>} />
                        <Route path="/privacy" element={<Policies/>} />
                        <Route path="/licenses" element={<Policies/>} />
                      </Routes>
                    }
                  />
                  <Route 
                    path='/licenses/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/licenses'/>} />
                        <Route path="/" element={<Licenses/>} />
                      </Routes>
                    }
                  />
              </Routes>
            <Footer />
          </Box>
          
          {/* MODALS */}
          <WalletSetUp show={showSetWallet} onHide={() => setShowSetWallet(false)}  />
          <Wallet show={showWallet} onHide={() => setShowWallet(false)} setShowVault={setShowVault} />
          <RankModal show={showVIP} onHide={() => setShowVIP(false)} />
          <CashierModal show={showVault} onHide={() => {setShowVault(false)}} setshowWallet={setShowWallet} setShowSetWallet={setShowSetWallet}  />
          
          {/* MOBILE DRAWER */}
          <SwipableaDrawer 
          toggleDrawer={toggleSwipableDrawer} 
          mobileSwipable={mobileSwipable} 
          setMobileSwipable={setMobileSwipable}
          setShowSetWallet={setShowSetWallet} 
          setShowWallet={setShowWallet} 
          setShowVIP={setShowVIP}
          setShowVault={setShowVault}
           />
          <SimpleBottomNavigation showVault={showVault} hideAllModals={hideAllModals} mobileSwipable={mobileSwipable} setMobileSwipable={setMobileSwipable} toggleDrawer={toggleSwipableDrawer} setShowVault={setShowVault} />
        
        </Box>  
        
      </div>
    </>
  );
}

export default App;
