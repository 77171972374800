import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link,Grid} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SideMenuSettings from '../components/Settings/SideMenuSettings';

import { useState, useEffect } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import SettingsDetails from '../components/Settings/SettingsDetails';

const menuItems= [
    {id: 0, name: 'General'},
    {id: 2, name: 'Preferences'},
    {id: 3, name: 'Ignored Users'},
    {id: 4, name: 'Verify'},
    {id: 5, name: 'Offers'},
]

export default function Settings(){
    const [selected, setSelected] = useState(menuItems[0].id);
    const history = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(history?.pathname?.split('/').length > 2){
            let item = history?.pathname?.split('/')[2]
            let index = menuItems.findIndex(x => {return x.name.toLowerCase() === item.toLowerCase().replaceAll('-', ' ')});
            if(index !== -1){
                setSelected(menuItems[index].name)
            }
        }else{
            navigate('/settings/general')
        }
    }, [history, navigate])

    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div> <SettingsIcon className="faded_icon"/> Settings </div>
                    <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Play
                        </Link> 
                        <Typography color="text.primary">Settings</Typography>
                    </Breadcrumbs>
                </Container>
            </div>

            <Container className="pt-5">
                <Grid container spacing={{ xs: 2, sm: 0, md: 0 }}>
                    <Grid item xs={12} sm={3} md={3}>
                        <SideMenuSettings menuItems={menuItems} selected={selected} setSelected={setSelected}/>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                        <SettingsDetails selected={selected}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}