import {Container} from 'react-bootstrap';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RankUser from '../components/Profile/RankUser';
import StepperRankClub from '../components/RankClub/StepperRankClub';
import PaperSlot from '../components/PaperSlot';


import { Grid, Paper , Breadcrumbs, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import FAQComponent from '../components/FAQ/FAQComponent';
import { useSelector } from 'react-redux';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#362b38',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: '8px'
}));

const boost = [
    {id: 0, name: 'Boost', desc: 'Every week and every month, expect a fresh bonus based on your recent games. The more you play, the higher the bonuses.', icon: 'https://stake.com/_app/immutable/assets/boost.DF2DDCCE.png'},
    {id: 1, name: 'Dedicated VIP Host', desc: 'Receive your own dedicated VIP host who will support and cater to your betting needs.', icon: 'https://stake.com/_app/immutable/assets/host.D2yyI_0f.png'},
    {id: 2, name: 'Recent Play Bonuses', desc: 'Having a rough streak of luck? Stake offers money back on losses every time you level up.', icon: 'https://stake.com/_app/immutable/assets/cashback.ChSM43vI.png'},
    {id: 3, name: 'Level-Ups', desc: 'Reach a new level and get paid. The level-ups get better the higher you go.', icon: 'https://stake.com/_app/immutable/assets/levelup.BPAS_FPf.png'},
    {id: 4, name: 'Bespoke', desc: 'Work with your dedicated VIP host to tailor benefits to your gaming needs.', icon: 'https://stake.com/_app/immutable/assets/bespoke.CxQq-dvx.png'},
]

export default function RankClub(){    
    const {user} = useSelector(state => state.user);

    return(
        <div className="main_page">
            <div className="header_page">
                <Container className="display_spacebetween mobile_display_col">
                    <Container>
                    <div> <MilitaryTechIcon className="faded_icon"/> Rank Club </div>
                        <Breadcrumbs  aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href={'/play'}>
                                Play
                            </Link> 
                            <Typography color="text.primary">Rank Club</Typography>
                        </Breadcrumbs>
                    </Container>

                    <Container className="pt-5 pb-4 rank_user_mobb">
                        <div>
                            {user &&
                            <RankUser showOnlyCurr={true} />
                            }
                        </div>
                    </Container>
                </Container>
            </div>
            
           <Container className="pt-5 pb-5">
                <div className="title_rankclub">
                    Casino Ranking System
                </div>
                <StepperRankClub />
           </Container>

           <Container className="pt-5 pb-5">
                <div className="title_rankclub">
                    Casino Ranking Benefits
                </div>
                <Grid container spacing={2}>
                    {boost.map(b => 
                        <Grid key={b.id} item xs={12} sm={6} md={6}>
                            <Item><PaperSlot icon={b.icon} name={b.name} desc={b.desc} /></Item>
                        </Grid>
                    )}
                </Grid>
           </Container>

           <FAQComponent />
        </div>
    )
}