import {
    Toolbar,
    Typography,
    Box,
    Button,
    Badge,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ButtonGroup
} from '@mui/material';

import AdbIcon from '@mui/icons-material/Adb';
import {Container} from 'react-bootstrap';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import SignIn from '../components/Authentication/SignIn';
import Register from '../components/Authentication/Register';
import ForgotPassword from '../components/Authentication/ForgotPassword';

import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {useNavigate} from 'react-router-dom';
//icons
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WalletIcon from '@mui/icons-material/Wallet';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {useLocation} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import axiosInstance from '../redux/axios';
import { logOutUser } from '../redux/reducers/UserReducer';
import { useDispatch } from 'react-redux';

const Item = styled(Paper)(({ theme }) => ({
    background: 'transaparent',
    padding: theme.spacing(0),
    textAlign: 'center',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    cursor: 'pointer'
  }));


const MenuTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="bottom" arrow  {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: 0
    },
}));

const NotificationTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="bottom-end" {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#171317',
        color: theme.palette.common.white,
        boxShadow: theme.shadows[3],
        fontSize: 11,
        padding: 0,
        minWidth: '20rem'
    },
}));


const MenuTitle = ({changeMenu, changeMenuLink, logOut, pathname}) => {
    const {user} = useSelector(state => state.user);

    return(
        <List className="list_menu_nav">
            {['Wallet', 'Cashier', 'Rank', 'Transactions', 'Settings', 'Live Support', 'Logout'].map((text, index) => (
            <ListItem 
            key={index}
            disablePadding 
            sx={{ display: 'block' }}
            onClick={() => 
                index === 0 ? (user?.is_user_verified ? changeMenu('wallet') : changeMenu('notSubmitterWallet') ) : 
                index === 1 ? changeMenu('cashier') : 
                index === 2 ? changeMenu('rank') : 
                index === 3 ? changeMenuLink('transactions') :
                index === 4 ? changeMenuLink('settings') :
                index === 5 ? changeMenu('support') :
                index === 6 ? logOut() :
                null}
            >
                <ListItemButton
                    sx={{
                    justifyContent: 'initial',
                    }}
                    className={pathname ? pathname === text.toLowerCase() ? 'selected_menu_nav' : '' : ''}
                >
                    <ListItemIcon
                    sx={{
                        minWidth: 0,
                        justifyContent: 'center',
                    }}
                    >
                        {index === 0 ? <WalletIcon/> : index === 1 ? <CurrencyExchangeIcon/> : index === 2 ? <MilitaryTechIcon/> : index === 3 ? <ReceiptLongIcon/> : index === 4 ? <SettingsIcon /> :  index === 5 ? <SupportAgentIcon/> : <LogoutIcon/> }
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
            </ListItem>
            ))}
        </List>
    )
}

const NotifTitle = ({handleCloseNotif}) => {
    return(
        <div>
            <div className="display_spacebetween model_title_nav">
                <div className="title_notif"> <NotificationsIcon className="faded_icon"/> <span> Notifications </span> </div>
                <div className="title_notif close_cursor">
                    <CloseIcon onClick={handleCloseNotif} className="faded_icon"/>
                </div>
            </div>
            <div className="modal_nav_body">
                <div className="no_notif_nav">
                    <div>
                        <NotificationsActiveIcon />
                    </div>
                    <div>No Notifications Available</div>
                    <div>Your interactions will be visible here</div>
                </div>
            </div>
        </div>
    )
}
export default function NavBar({AppBar, open, setOpen, setShowSetWallet, setShowWallet,setShowVIP, setShowVault}){
    const {user} = useSelector(state => state.user);
    const dispatch = useDispatch();
    console.log(user)
    const navigate = useNavigate();
    const history = useLocation();
    
    const [signIn, setSignIn] = useState(false);
    const [register, setRegister] = useState(false);
    const [forgotPass, setForgotPass] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [pathname, setPathName] = useState(null);

    useEffect(() => {
        let itemPrime = history?.pathname?.split('/')[1]
        if(itemPrime){
           const item = itemPrime?.toString().toLowerCase().replaceAll('-', ' ')
           if(item === 'transactions' || item === 'settings'){
                setPathName(item)
           }else{
                setPathName(null)
           }
        }
       
    }, [history])

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    const handleCloseNotif = () => {
        setOpenNotif(false);
    };

    const openChatWidget = () => {
        if(window.fcWidget){
            if(!window.fcWidget?.isOpen()){
                window.fcWidget.open()
            }else{
                window.fcWidget.close()
            }
        }
    }

    const changeMenuLink = (val) => {
        setOpenMenu(false)
        navigate(val.toLowerCase().replaceAll(' ', '-'))
    } 

    const changeMenu = (val) => {
        if(val === 'notSubmitterWallet'){
            setShowSetWallet(true)
        }
        if(val === 'wallet'){
            setShowWallet(true)
        }
        if(val === 'cashier'){
            setShowVault(true)
        }
        if(val === 'rank'){
            setShowVIP(true)
        }
        if(val === 'support'){
            openChatWidget()
        }

        setOpenMenu(false)
    }

    const logOut = async () => {
        try{
          await axiosInstance.get('auth/logout');
          dispatch(logOutUser())
          navigate('/')
        }catch{
          dispatch(logOutUser())
          navigate('/')
        }
      }

    return(
        <>
        <AppBar style={{backgroundColor: 'rgb(11 5 11)'}} position="fixed" open={open}>
            <Container>
                <Toolbar>
                    <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Photos
                    </Typography>
                    
                    {user &&
                    <Box sx={{ flexGrow: 0, paddingRight: '1rem'}}>
                        <ButtonGroup>
                            <div className="credits_display">
                                0.000000000 <CurrencyExchangeIcon className="ms-2" style={{width: '.9rem'}}/>
                            </div>
                            <Button onClick={() => setShowVault(true)} style={{padding: '.1rem'}} variant="contained"><CurrencyExchangeIcon style={{width: '1rem'}}/></Button>
                        </ButtonGroup>
                    </Box>
                    }
                    <Box sx={{ flexGrow: 0 }}>
                        <Grid container spacing={0}>
                        {user &&
                            <Grid container spacing={2}>
                                <Grid xs={6}>
                                    <Item>
                                        <ClickAwayListener onClickAway={handleCloseMenu}>
                                            <div>
                                                <MenuTooltip 
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                open={openMenu} 
                                                onClose={handleCloseMenu} 
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title={
                                                <MenuTitle
                                                changeMenu={changeMenu}
                                                changeMenuLink={changeMenuLink}
                                                logOut={logOut}
                                                pathname={pathname}
                                                />
                                                }>
                                                    <PersonIcon onClick={() => setOpenMenu(!openMenu)} />
                                                </MenuTooltip>
                                            </div>
                                        </ClickAwayListener>
                                    </Item>
                                </Grid>
                                <Grid xs={6}>
                                    <Item>
                                        <ClickAwayListener onClickAway={handleCloseNotif}>
                                            <div>
                                                <NotificationTooltip
                                                    open={openNotif} 
                                                    onClose={handleCloseNotif} 
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={
                                                    <NotifTitle
                                                    handleCloseNotif={handleCloseNotif}
                                                    />
                                                    }>
                                                    <Badge badgeContent={0} color="success">
                                                        <NotificationsIcon onClick={() => setOpenNotif(!openNotif)}  />
                                                    </Badge>
                                                </NotificationTooltip>
                                            </div>
                                        </ClickAwayListener>
                                    </Item>
                                </Grid>
                            </Grid>
                        }
                        {!user &&
                        <Grid container spacing={1}>
                            <Grid xs={6}>
                                <Item>
                                    <Button onClick={() => setSignIn(true)} className="signInBtn" variant="outlined">Sign In</Button>
                                </Item>
                            </Grid>
                            <Grid xs={6}>
                                <Item>
                                    <Button onClick={() => setRegister(true)} variant="contained">Register</Button>
                                </Item>
                            </Grid>
                        </Grid>
                        }
                       
                        </Grid>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

        <SignIn show={signIn} onHide={() => setSignIn(false)} setRegister={setRegister} setForgotPass={setForgotPass} />
        <Register show={register} onHide={() => setRegister(false)} setSignIn={setSignIn}/>
        <ForgotPassword show={forgotPass} onHide={() => setForgotPass(false)} />
        </>
    )
}