import { Grid } from '@mui/material';

export default function PaperSlot({name, desc, icon}){
    return(
        <Grid container spacing={1}>
            <Grid className="display_flex_center" style={{justifyContent: 'center'}} item xs={2}>
                <div className="ms-1 me-1">
                    <img style={{width: '100%'}} src={icon} alt="icon" />
                </div>
            </Grid>
            <Grid className="display_flex_center" style={{justifyContent: 'flex-start'}} item xs={10}>
                <div className="paper_slot_comp">
                    <div>{name}</div>
                    <div>{desc}</div>
                </div>
            </Grid>
        </Grid>
    )
}