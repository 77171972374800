import {Modal} from 'react-bootstrap';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { useState } from 'react';
import RankRewards from '../Profile/RankRewards';
import RankUser from '../Profile/RankUser';
import RankImprove from '../Profile/RankImprove';
import useMediaQuery from '@mui/material/useMediaQuery';

const steps = [
  {id: 0, name: 'Progress'},
  {id: 1, name: 'Rewards'},
]
export default function RankModal(props){

    const [activeStep, setActiveStep] = useState(0);
    const matches = useMediaQuery('(max-width:600px)');

    return(
        <Modal
        fullscreen={matches}
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-bs-theme="dark"
      >
        <Modal.Header className="header_wallet" closeButton>
          <Modal.Title id="title_header_wallet">
                <MilitaryTechIcon/> 
                Rank
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body className="pb-5">
            <div className="display_flex_center">
              <div className="display_spacebetween chashier_steps">
                {steps.map(s =>
                  <div onClick={() => {setActiveStep(s.id)}} className={activeStep === s.id ? 'active_cashier_step' : ''} key={s.id}>{s.name}</div>
                )}
              </div>
          </div>

          {activeStep === 1 &&
            <RankRewards />
          }
          {activeStep === 0 &&
          <div className="mt-3">
            <RankUser />
            <div className="pt-4 ps-3">
              <h6 className="h6_rank">Want to improve your rank?</h6>
              <RankImprove id={0} name="Wager on Slots" desc="Play & Wager on any slot games" />
            </div>
          </div>
          }
        </Modal.Body>
        <Modal.Footer className="rank_footer">
          Learn more about Casino Rank
        </Modal.Footer>
      </Modal>
    )
}