import { Avatar } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { useSelector } from 'react-redux';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#362e36',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#923FB5' : '#923fb58e',
    },
}));

const curr_rank = {id: 0, name: 'Herald', img: '/static/ranks/herald.png'};
const next_rank = {id: 1, name: 'Guardian ', img: '/static/ranks/guardian.png'}
export default function RankUser({showOnlyCurr}){
    const {user} = useSelector(state => state.user);

    return(
        <div className="display_flex_center">
            <div className="darker_bg">
                {!showOnlyCurr &&
                <div className="display_spacebetween">
                    <div className="display_spacebetween">
                        <Avatar className="avatar_user" alt="User Name" src="" />
                        <div className="ps-2 username_rank">{user?.first_name +' '+ user?.last_name}</div>
                    </div>

                    <div>
                        <img className="rankImg me-2" src={curr_rank.img} alt="rank" />
                    </div>
                </div>}

                <div className="pt-4 pb-4">
                    <div  className="display_spacebetween mb-1">
                        <div className="display_spacebetween rank_user_top">
                            <div>You current rank</div>
                        </div>

                        <div className="rank_user_top">
                            31.10%
                        </div>
                    </div>
                    <BorderLinearProgress variant="determinate" value={31.1} />
                    <div className="display_spacebetween mt-1">
                        <Typography sx={{ width: '33%', flexShrink: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <img className="rankImg1 me-1" src={curr_rank.img} alt="rank" />
                            <span className="pt-1 rank_user_bottom">{curr_rank.name}</span>
                        </Typography>

                        <Typography sx={{ width: '33%', flexShrink: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <img className="rankImg1 me-1" src={next_rank.img} alt="rank" />
                            <span className="pt-1 rank_user_bottom">{next_rank.name}</span>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}