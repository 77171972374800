import {Modal, Button, Form} from 'react-bootstrap';
import { useState } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';

export default function ForgotPassword(props){
    const { Formik } = formik;
    const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

    const validationSchema =  yup.object().shape({
        email: yup.string().required().email(),
    })

    const initialValues = {
        email: ''
    }

    return(
        <Modal
        {...props}
        size="md"
        aria-labelledby="title_signin"
        centered
        data-bs-theme="dark"
        >
            <Modal.Header className="header_auth" closeButton>
              <Modal.Title id="title_signin">
                Forgot Password
              </Modal.Title>
              
            </Modal.Header>
            <Modal.Body>
                <Formik
                validationSchema={validationSchema}
                validateOnChange={validateAfterSubmit}
                validateOnBlur={validateAfterSubmit}
                onSubmit={(values) => {
                    if(validationSchema.isValidSync(values)){
                        //
                    }
                }}
                
                initialValues={initialValues}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                        <Form data-bs-theme="dark" noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mt-4" controlId="auth.email">
                                <Form.Label>Email<span className="form_required">*</span></Form.Label>
                                <Form.Control  
                                    className="input_settings" 
                                    type="email" 
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
    
                            <Button className="submit_btn full_width" style={{paddingTop: '.8rem', paddingBottom: '.8rem'}}  onClick={() => {
                                setValidateAfterSubmit(true)
                            }} type="submit">Recover Password</Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
          </Modal>
        )
}