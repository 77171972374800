import * as React from 'react';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import MenuIcon from '@mui/icons-material/Menu';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SendIcon from '@mui/icons-material/Send';

import {useNavigate} from 'react-router-dom';
import {useLocation} from "react-router-dom";
import { useSelector } from 'react-redux';


export default function SimpleBottomNavigation({hideAllModals, toggleDrawer, setMobileSwipable, mobileSwipable, setShowVault,showVault}) {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const history = useLocation();
  const {user} = useSelector(state => state.user);

  const checkPath = React.useCallback(
    () => {
      if(!showVault && !mobileSwipable){
        if(window.fcWidget){
          if(window.fcWidget?.isOpen()){
            setValue(3);
            return;
          }
        }
        if(history.pathname === "/play"){
          setValue(1)
        }else{
          setValue(0)
        }
      }
      if(showVault){
        setValue(2)   
      }
    },
    [history, showVault, mobileSwipable]
  )

  React.useEffect(() => {
    if(window.fcWidget){
      window.fcWidget.on("widget:closed", function(resp) {
        checkPath();
      });
    }
  }, [checkPath])

  React.useEffect(() => {
    if(window.fcWidget){
      window.fcWidget.on("widget:opened", function(resp) {
        checkPath();
      });
    }
  }, [checkPath])
  
  React.useEffect(() => {
    checkPath()
  }, [checkPath, showVault, mobileSwipable])
  
  const closeChatWidget = () => {
    if(window.fcWidget){
      if(window.fcWidget?.isOpen()){
          window.fcWidget.close()
      }
    }
  }
  const openChatWidget = () => {
    setMobileSwipable(false)
    setShowVault(false)
    hideAllModals()
    if(window.fcWidget){
        if(!window.fcWidget?.isOpen()){
            window.fcWidget.open()
        }else{
            checkPath()
            window.fcWidget.close()
        }
    }
  }
  const goPlay = () =>{
    setMobileSwipable(false)
    setShowVault(false)
    closeChatWidget()
    hideAllModals()
    if(history.pathname !== '/play'){
      navigate('/')
    }
  }

  const changeMenu = () => {
    closeChatWidget()
    setShowVault(false)
    hideAllModals()
    setMobileSwipable(!mobileSwipable)

    if(mobileSwipable){
      checkPath()
    }
  }

  const changeTheCashier = () => {
    setMobileSwipable(false);
    setShowVault(!showVault);
    closeChatWidget();
    hideAllModals();

    if(showVault){
      checkPath()
    }
  }

  return (
    <Paper className="paper_bottom_nav" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: 'block', sm: 'none' } }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Menu" onClick={() => changeMenu()} icon={<MenuIcon />} />
        <BottomNavigationAction label="Play" onClick={() => goPlay()} icon={<AdfScannerIcon />} />
        {user && <BottomNavigationAction label="Cashier" onClick={() => changeTheCashier() } icon={<CurrencyExchangeIcon />} />}
        <BottomNavigationAction label="Chat" onClick={() => openChatWidget()} icon={<SendIcon />} />
      </BottomNavigation>
    </Paper>
  );
}