import { Container } from 'react-bootstrap';
import AdbIcon from '@mui/icons-material/Adb';
import { styled } from '@mui/material/styles';
import { Paper, Link, Grid } from '@mui/material';
import {Fragment} from 'react';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '4rem'
  }));

const casinoItems = [
    {id: 0, name: "Casino Games", link: "/play" },
    {id: 2, name: "Slots", link: "/play/group/slots" },
    {id: 1, name: "New Releases", link: "/play/group/new-releases" },
]

const supportItems = [
    {id: 1, name: "Live Support", link: "life_support" },
    {id: 2, name: "Help Center", link: "#" },
    {id: 3, name: "How to Guides", link: "#" },
    {id: 4, name: "Supported Crypto", link: "#" },
]

const socialItems = [
    {id: 0, name: "Discord", link: "#" },
    {id: 1, name: "Facebook", link: "#" },
    {id: 2, name: "Twitter", link: "#" },
    {id: 3, name: "Instagram", link: "#" },
]

const aboutItems = [
    {id: 0, name: "Privacy Policy", link: "/policies/privacy" },
    {id: 1, name: "Licenses", link: "/licenses" },
    {id: 2, name: "Terms of Service", link: "/policies/terms-of-service" },
]
export default function Footer(){
    const openChatWidget = () => {
        if(window.fcWidget){
            if(!window.fcWidget?.isOpen()){
                window.fcWidget.open()
            }
        }
    }

    const doLink = (link) => {
        if(link === 'life_support'){
            openChatWidget()
        }
    }
    return(
        <div className="footer_content">
            <Container>
                <div>
                <Grid container mb={2} mt={2} spacing={2}>
                    <Grid item xs={6} sm={3} md={3} lg={3} xl={3} xxl={3}>
                        <Item className="item_footer">
                            <div>
                                <div className="footer_heading">Casino</div>
                                {casinoItems.map(c =>
                                    <Fragment key={c.id}>
                                        <Link className="link_footer"  href={c.link}>{c.name}</Link> <br/>
                                    </Fragment>
                                )}
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={3} xl={3} xxl={3}>
                        <Item className="item_footer">
                            <div>
                                <div className="footer_heading">Support</div>
                                {supportItems.map(c =>
                                    <Fragment key={c.id}>
                                        <Link className="link_footer" onClick={() => !c.link?.toString().startsWith('/') ? doLink(c.link) : null} >{c.name}</Link> <br/>
                                    </Fragment>
                                )}
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={3} xl={3} xxl={3}>
                        <Item className="item_footer">
                            <div>
                                <div className="footer_heading">Social</div>
                                {socialItems.map(c =>
                                    <Fragment key={c.id}>
                                        <Link className="link_footer" href={c.link}>{c.name}</Link> <br/>
                                    </Fragment>
                                )}
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={3} xl={3} xxl={3}>
                        <Item className="item_footer">
                            <div>
                                <div className="footer_heading">About Us</div>
                                {aboutItems.map(c =>
                                    <Fragment key={c.id}>
                                        <Link className="link_footer" href={c.link}>{c.name}</Link> <br/>
                                    </Fragment>
                                )}
                            </div>
                        </Item>
                    </Grid>
                </Grid>
                </div>
                <div className="display_footer">
                    <div style={{marginBottom: '1rem'}}>
                        <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    </div>
                    <p>© 2024 Casino.com | All Rights Reserved.</p>
                    <p>Support <span className="text_white">support@casino.com</span></p>
                </div>
            </Container>
        </div>
    )
}