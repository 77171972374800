export default function SideMenuFAQ({menuItems, selected, setSelected}){
    return(
        <div className="transaction_sidemenu_cont">
            {menuItems.map(m => 
                <div 
                onClick={() => {setSelected(m.name);}} 
                className={selected === m.name ? "el_trans_sidemenu_selected" : "el_trans_sidemenu"} 
                key={m.id}>
                    {m.name}
                </div>
            )}
        </div>
    )
}