//icons
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Person4Icon from '@mui/icons-material/Person4';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ExpandMore from '@mui/icons-material/ExpandMore';
import WalletIcon from '@mui/icons-material/Wallet';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import HistoryIcon from '@mui/icons-material/History';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';

import {
    Divider,
    List,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Collapse,
} from '@mui/material';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Fragment} from 'react';
import {useLocation} from "react-router-dom";
import Wallet from '../components/Modals/WalletSetUp';
import axiosInstance from '../redux/axios';
import { logOutUser } from '../redux/reducers/UserReducer';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="top" arrow  {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
}));

export default function SideDrawer({DrawerHeader, Drawer, open, setOpen, setShowSetWallet, setShowWallet,setShowVIP, setShowVault}){
    const {user} = useSelector(state => state.user);
    
    const navigate = useNavigate();
    const history = useLocation();
    const dispatch = useDispatch();

    const [primeSelection, setPrimeSelection] = useState('play');
    const [secSelection, setSecSelection] = useState('');
    const [expanded, setExpanded] = useState('');
    const [openProfileTooltip, setOpenProfileTooltip] = useState(false);

    useEffect(() => {
        let itemPrime = history?.pathname?.split('/')[1]
        if(itemPrime){
            setPrimeSelection(itemPrime.replaceAll('-', ' '))
        }
        if(history?.pathname?.split('/').length > 2){
            let item = history?.pathname?.split('/')[2]
            if(item === 'group'){
                let item3 = history?.pathname?.split('/')[3]
                if(item3){
                    setSecSelection(item3?.replaceAll('-', ' ').toLowerCase())
                }
            }else{
                setSecSelection(item?.replaceAll('-', ' ').toLowerCase())
            }
        }else{
            setSecSelection('')
        }
    }, [history, setSecSelection])

   
    const handleCloseProfileTooltip = () => {
        setOpenProfileTooltip(false)
    }
    const handleOpenProfileTooltip = () => {
        setOpenProfileTooltip(true)
    }

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
        setExpanded('');
        setOpen(false);
    };

    const changeExpanded = (val) =>{
        if(expanded === val){
            setExpanded('');
        }else{
            setExpanded(val)
            setOpen(true)
        }
    }

    const changePrime = (val) => {
        setPrimeSelection(val)
        setSecSelection("")
        if(val === 'play'){
            navigate('/play')
        }else{
            navigate(val.toLowerCase().replaceAll(' ', '-'))
        }
    }

    const changeSecSelection = (text) => {
        setSecSelection(text)
        const link = text.toLowerCase().replaceAll(' ', '-');
        if(link === 'slots' || link ==='new-releases'){
            navigate('/play/group/'+text.toLowerCase().replaceAll(' ', '-'))
        }else{
            navigate('/play/'+text.toLowerCase().replaceAll(' ', '-'))
        }
    }

    const openChatWidget = () => {
        if(window.fcWidget){
            if(!window.fcWidget?.isOpen()){
                window.fcWidget.open()
            }else{
                window.fcWidget.close()
            }
        }
    }

    const logOut = async () => {
        try{
          await axiosInstance.get('auth/logout');
          dispatch(logOutUser())
          navigate('/')
        }catch{
          dispatch(logOutUser())
          navigate('/')
        }
      }

    return(
        <>
            <Drawer 
            sx={{
                display: { xs: 'none', sm: 'block' },
            }}
            variant="permanent" 
            open={open}
            >
                <DrawerHeader>
                    {open ? 
                        <div onClick={() => changePrime('play')} className={primeSelection === 'play' ? "play_btn play_btn_selected" : "play_btn"}>
                            <div className="absolute_play_img" />
                            <div className="relative_play_comp">
                                PLAY
                            </div>
                        </div> 
                    : <></>}
                    <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {open ? <MenuIcon /> : <MenuIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                {primeSelection === 'play' &&
                    <>
                        {user &&
                        <List className="list_decor">
                        {['Favourites', 'Recent'].map((text, index) => (
                            <LightTooltip key={index}  disableHoverListener={open} title={text}>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        }}
                                        selected={secSelection?.toString().toLowerCase() === text.toLowerCase()}
                                        onClick={() => changeSecSelection(text)}
                                    >
                                        <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                        >
                                        {index % 2 === 0 ? <StarBorderIcon /> : <HistoryIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </LightTooltip>
                        ))}
                        </List>}
                        <List className="list_decor">
                        {['Slots', 'New Releases'].map((text, index) => (
                            <LightTooltip key={index} disableHoverListener={open} title={text}>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    }}
                                    selected={secSelection?.toString().toLowerCase() === text.toLowerCase()}
                                    onClick={() => changeSecSelection(text)}
                                >
                                    <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                    >
                                    {index % 2 === 0 ? <AdfScannerIcon /> : <RocketLaunchIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                                </ListItem>
                            </LightTooltip>
                        ))}
                        </List>
                    </>
                }

                
                <List className="list_decor">
                    {user &&
                    <>
                    <LightTooltip open={openProfileTooltip} onClose={handleCloseProfileTooltip} onOpen={handleOpenProfileTooltip} disableHoverListener={open} title='Profile'>
                        <ListItem className={expanded === 'profile' ? 'sub_extended' : ''} onClick={() => {changeExpanded('profile'); setOpenProfileTooltip(false)}} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                                >
                                    <Person4Icon />
                                </ListItemIcon>
                                <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />
                                {open ? expanded === 'profile' ? <ExpandMore /> : <ChevronRightIcon /> : <></>}
                            </ListItemButton>
                        </ListItem>
                    </LightTooltip>
                    <Collapse className={expanded === 'profile' ? 'sub_extended_collapse' : ''} in={expanded === 'profile'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {['Wallet', 'Cashier', 'Rank','Transactions', 'Settings', 'Logout'].map((text, index) => (
                            <Fragment key={index}>
                                <ListItemButton
                                selected={primeSelection?.toString().toLowerCase() === text.toLowerCase()}
                                onClick={() => index === 3 || index === 4 ? changePrime(text) : text ==='Wallet' ? user?.is_user_verified ? setShowWallet(true) : setShowSetWallet(true) : text === "Rank" ? setShowVIP(true) : text === 'Cashier' ? setShowVault(true) : text === 'Logout' ? logOut() : null}
                                sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        {index === 0 ? <WalletIcon/> : index === 1 ? <CurrencyExchangeIcon/> : index === 2 ? <MilitaryTechIcon/> : index === 3 ? <ReceiptLongIcon/> : index === 4 ? <SettingsIcon /> : <LogoutIcon/> }
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                                {index === 5 && <Divider />}
                            </Fragment>
                        ))}
                        </List>
                    </Collapse></>
                    }

                    {['Rank Club'].map((text, index) => (
                        <LightTooltip key={index}  disableHoverListener={open} title={text}>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    }}
                                    selected={primeSelection?.toString().toLowerCase() === text.toLowerCase()}
                                    onClick={() => changePrime(text)}
                                >
                                    <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                    >
                                    {index % 2 !== 0 ? <Diversity3Icon /> : <MilitaryTechIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        </LightTooltip>
                    ))}
                </List>
                
                <Divider />

                <List className="list_decor">
                
                {['Support'].map((text, index) => (
                    <LightTooltip key={index}  disableHoverListener={open} title={text}>
                        <ListItem onClick={() => {openChatWidget()}} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            }}
                        >
                            <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            >
                            {index % 2 === 0 ? <SupportAgentIcon /> : <SupportAgentIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                        </ListItem>
                    </LightTooltip>
                ))}
                </List>
            </Drawer>
            <Wallet />
        </>
    )
}