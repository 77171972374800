
import WhatshotIcon from '@mui/icons-material/Whatshot';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

import {ButtonGroup, Button} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function HeaderPicks({text, notMore, scroller, goLeft, goRight, arrowLeft, arrowRight}){
    return(
        <div className="display_spacebetween">
            <div className="home_picks">
                <div>
                    {(text === 'Our picks' || text === 'Recommended') && <WhatshotIcon/>}
                    {text === 'Game of the Week' && <StarBorderIcon/>}
                    {text === 'Providers' && <LocalActivityIcon/>}
                    {text === 'New Releases' && <RocketLaunchIcon/>}
                    {text === 'Popular' && <WorkspacePremiumIcon/>}
                </div>
                <div>{text}</div>
            </div>
            {scroller &&
                <div style={{gap: '1rem'}} className="display_spacebetween">
                    {!notMore &&
                    <Button className="header_btn"> More </Button>
                    }
                    <ButtonGroup className="btn_group" color="secondary" variant="outlined"  aria-label="Basic button group">
                        <Button onClick={() => goLeft()} id={!arrowLeft ? 'btn_disabled1' : ''} ><ChevronLeftIcon/></Button>
                        <Button onClick={() => goRight()} id={!arrowRight ? 'btn_disabled2' : ''}><ChevronRightIcon/></Button>
                    </ButtonGroup>
                </div>
            }
        </div>
    )
}