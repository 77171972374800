import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useState} from 'react';

const ranks = [
    {id: 0, name: 'Herald', img: '/static/ranks/herald.png', 
    benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 1, name: 'Guardian ', img: '/static/ranks/guardian.png',
    benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 2, name: 'Crusader', img: '/static/ranks/crusader.png', benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 3, name: 'Legend', img: '/static/ranks/legend.png',benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 4, name: 'Ancient', img: '/static/ranks/ancient.png',benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 5, name: 'Divine', img: '/static/ranks/divine.png',benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 6, name: 'Immortal', img: '/static/ranks/immortal.png',benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 8, name: 'Immortal I - C', img: '/static/ranks/immotral_1.png',benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]}
]
export default function RankRewards(){
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return(
        <div className="rankAccord_cont">
            {ranks.map(r => 
                <Accordion className="accordion_rank" key={r.id} expanded={expanded === r.name} onChange={handleChange(r.name)}>
                        <AccordionSummary
                            className="accordion_rank_summary"
                            expandIcon={<ChevronLeftIcon />}
                            aria-controls={"panel"+r.id+"rank-content"}
                            id={"panel"+r.id+"rank-content"}
                        >
                            <Typography sx={{ width: '100%', flexShrink: 0, display: 'flex', alignItems: 'center' }}>
                                <img className="rankImg me-2" src={r.img} alt="rank" />
                                <span className="pt-1">{r.name}</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="rank_acc_details">
                            <ul className="rank_acc_ul">
                                {r.benefits && r.benefits.map(b =>

                                    <li key={b.id}>{b.name}</li>
                                )}   
                            </ul>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    )
}