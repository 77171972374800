import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link} from '@mui/material';
import AutoCompleteSearch from '../../components/Home/AutoCompleteSearch';

const favourites = []

export default function Favourite(){
    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div> Favourites </div>
                    <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Play
                        </Link> 
                        <Typography color="text.primary">Favourites</Typography>
                    </Breadcrumbs>
                </Container>
            </div>
            <Container style={{padding: '1rem', paddingTop: '2rem'}}>
               <div className="mobile_search_home">
                <AutoCompleteSearch />
               </div>
               <div >
                {!favourites.length && 
                    <div className="display_search_autocomplete display_nonefound_mobile"> No favourite games to display.</div>
                }
               </div>
            </Container>
        </div>
    )
}