import HeaderPicks from './HeaderPicks';
import {useState, useRef} from 'react';
import {Badge} from '@mui/material';
import { useNavigate } from "react-router-dom";

export default function OtherGames({text}){
    const [arrowLeft, setArrowLeft] = useState(false);
    const [arrowRight, setArrowRight] = useState(true);
    const [x, setX] = useState(0);
    const [maxX, setMaxX] = useState(200 * data?.length)
    const slider = useRef();
    const navigate = useNavigate();

    const goLeft = () => {
        if (x - 1085 >= 0) {
          slider.current.scrollTo(x - 1085, 0);
        } else {
          slider.current.scrollTo(0, 0);
        }
    }

    const goRight = () => {
        if (x + 1085 <= maxX) {
          slider.current.scrollTo(x + 1085, 0);
        } else {
          slider.current.scrollTo(maxX, 0);
        }
    }

    const onSlide = () => {
        let temp = (200*data?.length) - slider.current.offsetWidth - 20;
        setMaxX(temp)

        setX(slider.current.scrollLeft)
        setArrowLeft(slider.current.scrollLeft > 0 ? true : false)
        setArrowRight(slider.current.scrollLeft < maxX  ? true : false)
    }

    return(
        <>
            <HeaderPicks text={text} scroller={true} goLeft={goLeft} goRight={goRight} arrowLeft={arrowLeft} arrowRight={arrowRight}/>

            <div ref={slider} onScroll={()=> onSlide()} className="flex-games">
                {data && data.map(p =>
                    <div className="glid_inside_go" onClick={() => navigate('/play/slots/games/'+p.id)} key={p.id}>
                        <Badge 
                        className="badge_other_games"
                        badgeContent="New"
                        slotProps={{ badge: { className: 'my-badge' } }} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }} 
                        color="success">
                            <img className="other_game_img" src={p.img} alt="provider" />
                        </Badge>
                        <div className="online_grid"><div className="green_online"></div>{p.playing} <span className="shadowed_text ms-1"> playing</span></div>
                    </div>
                )}
            </div>
        </>
    )
}

const data = [    
    { id: 1,title: 'The Shawshank Redemption', playing: 1994, img: '/static/test/1.avif' },
    { id: 2,title: 'The Godfather', playing: 1972 , img: '/static/test/2.avif'},
    { id: 3,title: 'The Godfather: Part II', playing: 1974, img: '/static/test/3.avif' },
    { id: 4,title: 'The Shawshank Redemption', playing: 1994, img: '/static/test/1.avif' },
    { id: 5,title: 'The Godfather', playing: 1972 , img: '/static/test/2.avif'},
    { id: 6,title: 'The Godfather: Part II', playing: 1974, img: '/static/test/3.avif' },
    { id: 7,title: 'The Shawshank Redemption', playing: 1994, img: '/static/test/1.avif' },
    { id: 8,title: 'The Godfather', playing: 1972 , img: '/static/test/2.avif'},
    { id: 9,title: 'The Godfather: Part II', playing: 1974, img: '/static/test/3.avif' },
    { id: 10,title: 'The Shawshank Redemption', playing: 1994, img: '/static/test/1.avif' },
    { id: 11,title: 'The Godfather', playing: 1972 , img: '/static/test/2.avif'},
    { id: 12,title: 'The Godfather: Part II', playing: 1974, img: '/static/test/3.avif' },
]