import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link,Grid} from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SideMenuTransactions from '../components/Transactions/SideMenuTransactions';

import { useState, useEffect } from 'react';
import TransactionDetails from '../components/Transactions/TransactionDetails';
import {useLocation, useNavigate} from "react-router-dom";

const menuItems= [
    {id: 0, name: 'Deposits'},
    {id: 1, name: 'Withdrawals'},
    {id: 2, name: 'Other'}
]

const steps = [
    {id: 0, name: 'Crypto'},
    {id: 1, name: 'Credit'},
]

const stepsOther = [
    {id: 0, name: 'All'},
    {id: 1, name: 'Credits'},
    {id: 2, name: 'Bonus'},
    {id: 3, name: 'Drop'},
    {id: 4, name: 'Claim'},
]

export default function Transactions(){
    const [selected, setSelected] = useState(menuItems[0].id);
    const [activeStep, setActiveStep] = useState(0);
    const history = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(history?.pathname?.split('/').length > 2){
            let item = history?.pathname?.split('/')[2]
            let index = menuItems.findIndex(x => {return x.name.toLowerCase() === item.toLowerCase()});
            if(index !== -1){
                setSelected(menuItems[index].id)
            }
        }else{
            navigate('/transactions/deposits')
        }
    }, [history, navigate])

    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div> <ReceiptLongIcon className="faded_icon"/> Transactions </div>
                    <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Play
                        </Link> 
                        <Typography color="text.primary">Transactions</Typography>
                    </Breadcrumbs>
                </Container>
            </div>

            <Container className="pt-5">
                <Grid container spacing={{ xs: 2, sm: 0, md: 0 }}>
                    <Grid item xs={12} sm={3} md={3}>
                        <SideMenuTransactions menuItems={menuItems} selected={selected} setSelected={setSelected} setActiveStep={setActiveStep}/>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                        <TransactionDetails menuItems={menuItems} selected={selected} steps={selected !== 2 ? steps : stepsOther} activeStep={activeStep} setActiveStep={setActiveStep} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}