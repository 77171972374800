import {Modal, Button, Form} from 'react-bootstrap';
import { useState , useEffect, useCallback } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setCountries } from '../../redux/reducers/PageReducer';
import useMediaQuery from '@mui/material/useMediaQuery';
import axiosInstance from '../../redux/axios';
import { setAccessToken, setUser } from '../../redux/reducers/UserReducer';

const months = ["January","February","March","April","May","June","July",
"August","September","October","November","December"];

function getMonthNumber(monthName) {
    const monthMap = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12'
    };
  
    const monthNumber = monthMap[monthName];
  
    if (monthNumber) {
      return monthNumber;
    } else {
      return 'Invalid month name';
    }
}

export default function Register(props){
    const matches = useMediaQuery('(max-width:600px)');
    const { Formik } = formik;
    const {countries} = useSelector(state => state.page);
    
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

    const validationSchema =  yup.object().shape({
        email: yup.string().required().email(),
        username: yup.string().required(),
        password: yup.string().required().min(6),
        day: yup.number().required().positive().integer().min(1).max(31),
        month: yup.string().required().notOneOf(["Month"], "This field is required.").when('selectedValue', {
            is: val => val && val !== 'Month', // Only require if value is not "Month" and is not empty
            then: yup.string().required('Please select a value'),
        }),
        year: yup.number().required().positive().integer().min(1900).max(parseInt(new Date().getFullYear())-17),
       
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
    })

    const validatePhoneNumber = (phone) => {
        const regex = /^\+\d{1,3}\d{7,14}$/;
        return regex.test(phone);
    };

    const initialValues = {
        email: '',
        username: '',
        password: '',
        day: '',
        month: '',
        year: '',
        phone_prefix: '',
        phone_number: '',
        terms: false
    }

    const getCountries = useCallback(
        async () => {
          try{
              const response = await fetch("https://restcountries.com/v2/all");
              const c = await response.json();
              dispatch(setCountries(c));
          }catch{}
        },
        [dispatch],
      )
    useEffect(() => {
        if(!countries?.length){
            getCountries()
        }
    }, [countries, getCountries])

    return(
    <Modal
    size="md"
    {...props}
    fullscreen={matches}
    aria-labelledby="title_signin"
    centered
    data-bs-theme="dark"
    >
        <Modal.Header className="header_auth" closeButton>
          <Modal.Title id="title_signin">
            Create an Account
          </Modal.Title>
          
        </Modal.Header>
        
        <Modal.Body className="modal_body_config">
            <Formik
            validationSchema={validationSchema}
            validateOnChange={validateAfterSubmit}
            validateOnBlur={validateAfterSubmit}
            onSubmit={async (values) => {
                if(validationSchema.isValidSync(values)){
                    try{
                        const formData = new FormData();
                        formData.append('email', values.email);
                        formData.append('username', values.username)
                        formData.append('password', values.password)
                        formData.append('password_confirmation', values.password)
                        formData.append('date_of_birth', values.year + '-' + getMonthNumber(values.month)+'-'+ values.day)

                        if(values.phone_number !== '' && values.phone_prefix !== ''){
                            const parts = values.phone_prefix.split(' ');
                            const prefix = parts[parts.length - 1];

                            if(validatePhoneNumber(prefix + values.phone_number)){
                                formData.append('phone_number', values.phone_prefix + values.phone_number)
                            }else{
                                setError('Invalid phone number');
                                return;
                            }
                        }
                        const response = await axiosInstance.post('auth/register',formData)
                       
                        if(response?.data?.access_token){
                            await dispatch(setAccessToken(response?.data?.access_token))
                        }

                        if(response?.data?.user){
                            dispatch(setUser(response?.data?.user))
                            props.onHide()
                        }else{
                            const response1 = await axiosInstance.get('auth/me');
                     
                            if(response1.data?.data){
                                dispatch(setUser(response1?.data?.data))
                                props.onHide()
                            }
                        }
                    }catch(e){
                        if(e.response?.data?.message){
                            setError(e.response?.data?.message);
                        }
                        if(e.response?.data?.error){
                            setError(e.response?.data?.error);
                        }
                    }
                }else{
                    setError('Fill in the form')
                }
            }}
            initialValues={initialValues}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        setError('')
                    }, [values]);

                    return (
                    <Form data-bs-theme="dark" noValidate onSubmit={handleSubmit}>
                        <div className="form_modal_in">
                            <Form.Group className="mt-2" controlId="auth.email">
                                <Form.Label>Email <span className="form_required">*</span></Form.Label>
                                <Form.Control  
                                    className="input_settings" 
                                    type="email" 
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="auth.username">
                                <Form.Label>Username <span className="form_required">*</span></Form.Label>
                                <Form.Control  
                                    className="input_settings" 
                                    type="text" 
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.username && !errors.username}
                                    isInvalid={!!errors.username}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.username}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mt-2" controlId="auth.password">
                                <Form.Label>Password <span className="form_required">*</span></Form.Label>
                                <Form.Control  
                                    className="input_settings" 
                                    type="password" 
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Grid item xs={12}>
                                <Form.Group className="mt-2" controlId="auth.date_of_birth">
                                    <Form.Label>Date of Birth <span className="form_required">*</span></Form.Label>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Form.Control   
                                                type="number" 
                                                name="day"
                                                value={values.day}
                                                onChange={handleChange}
                                                isValid={touched.day && !errors.day}
                                                placeholder="DD"
                                                isInvalid={!!errors.day}
                                                step="any"
                                                min="1"
                                                max="31"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.day}
                                            </Form.Control.Feedback>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Form.Select 
                                            name="month"
                                            value={values.month}
                                            onChange={handleChange}
                                            isValid={touched.month && !errors.month}
                                            isInvalid={!!errors.month}
                                            placeholder="Month" 
                                            aria-label="Month">
                                                <option>Month</option>
                                                {months.map(m =>  
                                                    <option key={m} value={m}>{m}</option>
                                                )}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                This field is required.
                                            </Form.Control.Feedback>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Form.Control   
                                                type="number" 
                                                name="year"
                                                value={values.year}
                                                onChange={handleChange}
                                                isValid={touched.year && !errors.year}
                                                placeholder="YYYY"
                                                isInvalid={!!errors.year}
                                                step="any"
                                                min="1900"
                                                max={parseInt(new Date().getFullYear())-17}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.year}
                                            </Form.Control.Feedback>
                                        </Grid>
                                    </Grid>
                                </Form.Group>
                            </Grid>
                            
                            <Form.Group className="mt-2" controlId="auth.phone">
                                <Form.Label>Phone number (Optional)</Form.Label>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Form.Select 
                                        className="input_settings" 
                                        name="phone_prefix"
                                        value={values.phone_prefix}
                                        onChange={handleChange}
                                        aria-label="Phone Prefix"
                                        >
                                            <option></option>
                                            {countries && countries.length > 0 &&
                                            countries.map(c => 
                                                <option value={c.name + ' +' + c.callingCodes} key={c.name}>{c.name + ' +' + c.callingCodes}</option>
                                            )}
                                        </Form.Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Form.Control  
                                            className="input_settings" 
                                            name="phone_number"
                                            type="text" 
                                            value={values.phone_number}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Form.Group>
                        </div>  
                        <Form.Group className="mb-1 mt-4">
                            <Form.Check
                            required
                            name="terms"
                            label="Agree to out Terms & Conditions"
                            onChange={handleChange}
                            isInvalid={!!errors.terms}
                            feedback={errors.terms}
                            feedbackType="invalid"
                            id="validationFormik0"
                            />
                        </Form.Group>
                        <Button className="submit_btn full_width" style={{paddingTop: '.8rem', paddingBottom: '.8rem'}}  onClick={() => {
                            setValidateAfterSubmit(true)
                        }} type="submit">Register</Button>
                         {error !== '' && <div className="error_p pt-2">{error}</div>}
                    </Form>
                )
                }}
            </Formik>

            <div>
                <div className="display_flex_center pt-4 pb-4">
                    <div className="or_auth">
                        <span>OR</span>
                    </div>
                </div>

                <div className="display_flex_center pt-4">
                    <div className="two_parts_auth">
                        Already have an account? <span onClick={() => {props.onHide();props.setSignIn(true)}}>Sign In</span>
                    </div>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    )
}