import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import AutoCompleteSearch from '../../../components/Home/AutoCompleteSearch';

export default function ProvidersCollection(){
    const navigate = useNavigate();

    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div> Providers </div>
                   <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Play
                        </Link> 
                        <Typography color="text.primary">Providers</Typography>
                    </Breadcrumbs>
                </Container>
            </div>
            <Container style={{padding: '1rem', paddingTop: '2rem'}}>
                <div className="mobile_search_home">
                    <AutoCompleteSearch />
                </div>

                <Grid container spacing={2} className="flex-providers-collection">
                    {providers && providers.map(p =>
                        <Grid onClick={()=> {navigate('/play/group/'+p.name.toLowerCase().replaceAll(' ', '-'))}} item xs={6} sm={4} md={4} lg={2} xl={2} xxl={2} key={p.id}>
                            <div>
                                <img src={p.img} alt="provider" />
                            </div>
                        </Grid>
                    )}
                </Grid>

               
            </Container>
        </div>
    )
}


const providers=[
    {id: 0, img: '/static/providers/0.png', name: 'BackSeat Gaming'},
    {id: 1, img: '/static/providers/1.png', name: 'BGaming'},
    {id: 2, img: '/static/providers/2.png', name: 'Twist'},
    {id: 3, img: '/static/providers/3.png', name: 'Titan Gaming'},
    {id: 4, img: '/static/providers/4.png', name: 'Avatar UX'},
    {id: 5, img: '/static/providers/5.png', name: 'Popiplay'},
    {id: 6, img: '/static/providers/6.png', name: 'Belatra Games'},
    {id: 7, img: '/static/providers/7.png', name: 'Elk'},
    {id: 8, img: '/static/providers/0.png', name: 'BackSeat Gaming1'},
    {id: 9, img: '/static/providers/1.png', name: 'BGaming1'},
    {id: 10, img: '/static/providers/2.png', name: 'Twist1'},
    {id: 11, img: '/static/providers/3.png', name: 'Titan Gaming1'},
    {id: 12, img: '/static/providers/4.png', name: 'Avatar UX1'},
    {id: 13, img: '/static/providers/5.png', name: 'Popiplay1'},
    {id: 14, img: '/static/providers/6.png', name: 'Belatra Games1'},
    {id: 15, img: '/static/providers/7.png', name: 'Elk1'},
]

