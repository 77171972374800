import {Container} from 'react-bootstrap';
import { Grid } from '@mui/material';
import AutoCompleteSearch from '../../../components/Home/AutoCompleteSearch';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import SortIcon from '@mui/icons-material/Sort';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
    Divider,
    Badge,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import Providers from '../../../components/Home/Providers';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const MenuTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="bottom" arrow  {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: 0,
        maxHeight: '50vh',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
}));


const MenuTitle = ({sort, setSort, setExpandSort,setFilterProviders, filterProviders}) => {
    const updateArray = (item) => {
        if(filterProviders.filter(x=> {return x.name.toLowerCase() === item.name.toLowerCase()}).length === 0 ){
            setFilterProviders(
                [...filterProviders, item]
            )
        }else{
            setFilterProviders(
                filterProviders.filter(a => a.name.toLowerCase() !== item.name.toLowerCase())
              );
        }
    }
    return(
        <List className="list_menu_nav">
            {sort && sorting.map((text, index) => (
            <ListItem 
            key={index}
            disablePadding 
            sx={{ display: 'block' }}
            onClick={() => {setSort(text);setExpandSort(false)}}
            >
                <ListItemButton
                    sx={{
                    justifyContent: 'initial',
                    }}
                    className={sort?.name.toLowerCase() === text.name.toLowerCase() ? 'selected_menu_nav' : ''}
                >
                    <ListItemIcon
                    sx={{
                        minWidth: 0,
                        justifyContent: 'center',
                    }}
                    >
                        
                    </ListItemIcon>
                    <ListItemText primary={text.name} />
                </ListItemButton>
            </ListItem>
            ))}

            {filterProviders && 
            <>
                {providers.map((text, index) => (
                    <ListItem 
                    key={index}
                    disablePadding 
                    sx={{ display: 'block' }}
                    onClick={() => {updateArray(text)}}
                    >
                        <ListItemButton
                            sx={{
                            justifyContent: 'initial',
                            }}
                        >
                            <ListItemIcon
                            sx={{
                                minWidth: 0,
                                justifyContent: 'center',
                            }}
                            >
                                {filterProviders.filter(x=> {return x.name.toLowerCase() === text.name.toLowerCase()}).length > 0 ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                            </ListItemIcon>
                            <ListItemText primary={
                                <div>{text.name} <span className='dropdown_span_count'>{text.num_games}</span></div>
                            } />
                        </ListItemButton>
                    </ListItem>
                    ))}
                    <Divider sx={{borderColor: 'rgb(0 0 0 / 12%)'}} />
                    <ListItem 
                    disablePadding 
                    sx={{ display: 'block' }}
                    onClick={() => {setFilterProviders([])}}
                    >
                        <ListItemButton
                            sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                            }}
                        >
                            <ListItemText primary={"Clear All"} />
                        </ListItemButton>
                    </ListItem>
                </>
                }
        </List>
    )
}

const sorting = [
    {id: 0, name: 'A-Z'},
    {id: 1, name: 'Z-A'},
    {id: 2, name: 'Popular'},
    {id: 3, name: 'Release Date'}
]

export default function TagsCollection(){
    const navigate = useNavigate();
    const {tag_name} = useParams();
    const [tag, setTag] = useState(null);
    const [expandSort, setExpandSort] = useState(false);
    const [sort, setSort] = useState(sorting[0]);

    const [extendFilter, setExtendFilter] = useState(false);
    const [filterProviders, setFilterProviders] = useState([]);

    const handleCloseExtendSort = () => {
        setExpandSort(false);
    }
    const handleCloseExtendFilter = () => {
        setExtendFilter(false);
    }
    

    useEffect(() => {
        setTag(null)
        if(tag_name){
            let index = tags.findIndex(x => {return x.name.toLowerCase().replaceAll(' ', '-') === tag_name});
            if(index !== -1){
                setTag(tags[index]);
            }
        }
    }, [tag_name])
    return(
        <div className="main_page">
            <div className="header_page">
                 <Container>
                    <div> {tag ? tag.name : ''} </div>
                </Container>
            </div>
            <Container style={{marginTop: '2rem'}}>
                <div className="mobile_search_home">
                    <AutoCompleteSearch />
                </div>
                <div className="display_spacebetween space_top">
                    {tag?.type === "tag" &&
                    <div className="sort_by_container">
                        <div>
                            <SortIcon/>
                            <span className="ps-1">Filter By</span> 
                        </div>
                        <ClickAwayListener onClickAway={handleCloseExtendFilter}>
                            <div>
                                <MenuTooltip 
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                open={extendFilter} 
                                onClose={handleCloseExtendFilter} 
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                <MenuTitle
                                filterProviders={filterProviders}
                                setFilterProviders={setFilterProviders}
                                setExpandSort={setExtendFilter}
                                />
                                }>
                                    <div onClick={() => {setExtendFilter(!extendFilter)}} className="view_all_btn">
                                        <div> Providers {filterProviders.length > 0 && <span className="dropdown_span_count">{filterProviders.length}</span>}</div>
                                        {extendFilter ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                    </div>
                                </MenuTooltip>
                            </div>
                        </ClickAwayListener>
                    </div>
                    }
                    {tag?.type === "provider" &&
                         <div onClick={() => {navigate('/play/providers')}} className="view_all_btn">
                            <div>View All Providers</div>
                        </div>
                    }
                    <div className="sort_by_container">
                        <div>
                            <SortIcon/>
                            <span className="ps-1">Sort By</span> 
                        </div>
                        <ClickAwayListener onClickAway={handleCloseExtendSort}>
                            <div>
                                <MenuTooltip 
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                open={expandSort} 
                                onClose={handleCloseExtendSort} 
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                <MenuTitle
                                sort={sort}
                                setSort={setSort}
                                setExpandSort={setExpandSort}
                                />
                                }>
                                     <div onClick={() => {setExpandSort(!expandSort)}} className="view_all_btn">
                                        <div>{sort ? sort.name : 'A-Z'}</div>
                                        {expandSort ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                    </div>
                                </MenuTooltip>
                            </div>
                        </ClickAwayListener>
                       
                    </div>
                </div>

                {tag &&
                    <Grid className="pt-4 pb-5" container spacing={1}>
                        {data && data.map(p =>
                            <Grid onClick={() => navigate('/play/slots/games/'+p.id)}  item xs={4} sm={3} md={3} lg={2} xl={2} xxl={2} key={p.id}>
                                <div className="glid_inside_go" key={p.id}>
                                    <Badge 
                                    className="badge_other_games"
                                    badgeContent="New"
                                    slotProps={{ badge: { className: 'my-badge' } }} 
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }} 
                                    color="success">
                                        <img className="other_game_img" src={p.img} alt="provider" />
                                    </Badge>
                                    <div className="online_grid"><div className="green_online"></div>{p.playing} <span className="shadowed_text ms-1"> playing</span></div>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                }

                {!tag &&
                    <div className="notFound_display">
                        <LocalActivityIcon className="icon_transactions"/>
                        <div>We are unable to find this collection</div>
                        <div>Please check for typos, or it might be that the collection is no longer available.</div>
                    </div>
                }
                <div style={{paddingBottom: '2rem'}}>
                    <Providers />
                </div>
            </Container>
        </div>
    )
}


const tags=[
    {id: 0, name: 'Bonus Buy', type: 'tag'},
    {id: 1, name: 'Candy', type: 'tag'},
    {id: 2, name: 'Cascading', type: 'tag'},
    {id: 4, name: 'Recommended Games', type: 'tag'},
    {id: 5, name: 'Slots', type: 'tag'},
    {id: 6, name: 'New Releases', type: 'tag'},
    {id: 10, img: '/static/providers/0.png', name: 'Pragmatic Play', num_games: '62', type: 'provider'},
    {id: 11, img: '/static/providers/1.png', name: 'BGaming', num_games: '31', type: 'provider'},
    {id: 12, img: '/static/providers/2.png', name: 'Twist', num_games: '8', type: 'provider'},
    {id: 13, img: '/static/providers/3.png', name: 'Titan Gaming', num_games: '83', type: 'provider'},
    {id: 14, img: '/static/providers/4.png', name: 'Avatar UX', num_games: '114', type: 'provider'},
    {id: 15, img: '/static/providers/5.png', name: 'Popiplay', num_games: '43', type: 'provider'},
    {id: 16, img: '/static/providers/6.png', name: 'Belatra Games', num_games: '55', type: 'provider'},
    {id: 17, img: '/static/providers/7.png', name: 'Elk', num_games: '78', type: 'provider'},
    {id: 18, img: '/static/providers/0.png', name: 'BackSeat Gaming', num_games: '4', type: 'provider'},
    {id: 19, img: '/static/providers/1.png', name: 'BGaming1', num_games: '96', type: 'provider'},
    {id: 20, img: '/static/providers/2.png', name: 'Twist1', num_games: '150', type: 'provider'},
    {id: 21, img: '/static/providers/3.png', name: 'Titan Gaming1', num_games: '13', type: 'provider'},
    {id: 22, img: '/static/providers/4.png', name: 'Avatar UX1', num_games: '81', type: 'provider'},
    {id: 23, img: '/static/providers/5.png', name: 'Popiplay1', num_games: '267', type: 'provider'},
    {id: 24, img: '/static/providers/6.png', name: 'Belatra Games1', num_games: '135', type: 'provider'},
    {id: 25, img: '/static/providers/7.png', name: 'Elk1', num_games: '12', type: 'provider'},
]

const data = [    
    { id: 1,title: 'The Shawshank Redemption', playing: 1994, img: '/static/test/1.avif' },
    { id: 2,title: 'The Godfather', playing: 1972 , img: '/static/test/2.avif'},
    { id: 3,title: 'The Godfather: Part II', playing: 1974, img: '/static/test/3.avif' },
    { id: 4,title: 'The Shawshank Redemption', playing: 1994, img: '/static/test/1.avif' },
    { id: 5,title: 'The Godfather', playing: 1972 , img: '/static/test/2.avif'},
    { id: 6,title: 'The Godfather: Part II', playing: 1974, img: '/static/test/3.avif' },
    { id: 7,title: 'The Shawshank Redemption', playing: 1994, img: '/static/test/1.avif' },
    { id: 8,title: 'The Godfather', playing: 1972 , img: '/static/test/2.avif'},
    { id: 9,title: 'The Godfather: Part II', playing: 1974, img: '/static/test/3.avif' },
    { id: 10,title: 'The Shawshank Redemption', playing: 1994, img: '/static/test/1.avif' },
    { id: 11,title: 'The Godfather', playing: 1972 , img: '/static/test/2.avif'},
    { id: 12,title: 'The Godfather: Part II', playing: 1974, img: '/static/test/3.avif' },
]

const providers=[
    {id: 0, img: '/static/providers/0.png', name: 'Pragmatic Play', num_games: '62'},
    {id: 1, img: '/static/providers/1.png', name: 'BGaming', num_games: '31'},
    {id: 2, img: '/static/providers/2.png', name: 'Twist', num_games: '8'},
    {id: 3, img: '/static/providers/3.png', name: 'Titan Gaming', num_games: '83'},
    {id: 4, img: '/static/providers/4.png', name: 'Avatar UX', num_games: '114'},
    {id: 5, img: '/static/providers/5.png', name: 'Popiplay', num_games: '43'},
    {id: 6, img: '/static/providers/6.png', name: 'Belatra Games', num_games: '55'},
    {id: 7, img: '/static/providers/7.png', name: 'Elk', num_games: '78'},
    {id: 8, img: '/static/providers/0.png', name: 'BackSeat Gaming', num_games: '4'},
    {id: 9, img: '/static/providers/1.png', name: 'BGaming1', num_games: '96'},
    {id: 10, img: '/static/providers/2.png', name: 'Twist1', num_games: '150'},
    {id: 11, img: '/static/providers/3.png', name: 'Titan Gaming1', num_games: '13'},
    {id: 12, img: '/static/providers/4.png', name: 'Avatar UX1', num_games: '81'},
    {id: 13, img: '/static/providers/5.png', name: 'Popiplay1', num_games: '267'},
    {id: 14, img: '/static/providers/6.png', name: 'Belatra Games1', num_games: '135'},
    {id: 15, img: '/static/providers/7.png', name: 'Elk1', num_games: '12'},
]