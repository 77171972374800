import { Form } from 'react-bootstrap';
import {FormControl, OutlinedInput,InputAdornment, ButtonGroup, Button } from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField
} from '@mui/material';


const MenuTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="bottom" {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
      color: '#161316',
  },
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#161316',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 0,
      maxHeight: '40vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100vw'
  },
}));

const MenuTitle = ({currencies, setShowMore, changeCurrency, currency}) => {
  const [search, setSearch] = useState('');

  return(
      <List className="list_menu_nav">
         <div>
            <TextField
            dense
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="search_currencies"
            sx={{ m: 1, width: '96%' }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
              endAdornment: 
              <InputAdornment position="end">
                  <CloseIcon onClick={() => {setSearch('')}}/>
              </InputAdornment>
            }}
            placeholder="Search currency"
            />
          </div>
          {currencies.filter(x=> {return x.value.toLowerCase().includes(search.toLowerCase())}).map((c, index) => (
          <ListItem 
          key={index}
          disablePadding 
          sx={{ display: 'block' }}
          onClick={() => {setShowMore(false);changeCurrency(c)}}>
              <ListItemButton
                  sx={{
                  justifyContent: 'initial',
                  }}
                  className={currency?.id === c.id ? 'selected_menu_nav' : ''}
              >
                  <ListItemIcon
                  sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                  }}
                  >
                      
                  </ListItemIcon>
                  <ListItemText primary={<div key={c.value} className="currency-option">
                            <span className="pe-2">{c.amount}</span>
                            <img src={c.symbol} alt="token" />
                            <span className="ps-2">{c.value}</span>
                        </div>} />
              </ListItemButton>
          </ListItem>
          ))}
      </List>
  )
}


export default function UserCurrencyAmount({currencies, currency, setCurrency, setAmount, amount, defaultVal}){
    const [showMore, setShowMore] = useState(false);

    const changeAmount = (val) => {
      if(val === ''){
        setAmount('')
      }else{
        if(val > 0){
          setAmount(val)
        }else{
          setAmount(defaultVal)
        }
      }
    }

    const handleCloseMenu = () => {
      setShowMore(false)
    }

    const changeCurrency = (e) => {
      setCurrency(e)
      changeAmount(defaultVal)
    }
    return(
        <div className="display_flex_center">
            <div className="currency_container">
                <Form.Label>Currency</Form.Label>   

                <ClickAwayListener onClickAway={handleCloseMenu}>
                    <div>
                        <MenuTooltip 
                        PopperProps={{
                            disablePortal: true,
                        }}
                        open={showMore} 
                        onClose={handleCloseMenu} 
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                        <MenuTitle currencies={currencies} setShowMore={setShowMore} changeCurrency={changeCurrency} currency={currency}/>
                        }>
                            <div onClick={() => {setShowMore(!showMore)}} className="currencty_displayer">
                                {currency &&
                                <div className="currency-option">
                                  <span className="pe-2">{currency.amount}</span>
                                  <img src={currency.symbol} alt="token" />
                                  <span className="ps-2">{currency.value}</span>
                                </div>
                                }
                                <div>
                                {showMore ? <ExpandLessIcon/> : <ExpandMoreIcon/>} 
                                </div>
                            </div>
                        </MenuTooltip>
                    </div>
                </ClickAwayListener>
            </div>

            <div className="display_column full_width">
              <Form.Label>
                <div className="display_spacebetween">
                  <div>Amount</div>
                  <div>$0.00</div>
                </div>
              </Form.Label>   
              <ButtonGroup aria-label="Basic example">
                <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                  <OutlinedInput
                    className="input_modal"
                    type="number"
                    value={amount}
                    placeholder={defaultVal}
                    onChange={(e) => changeAmount(e.target.value)}
                    min={defaultVal}
                    endAdornment={<InputAdornment position="end">{currency ? <img className="img_token" src={currency.symbol} alt="token" /> : <></>}</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
                <Button className="modal_btn" variant="contained">MAX</Button>
              </ButtonGroup>
            </div>
        </div>
    )
}