import { useParams, useNavigate } from 'react-router-dom';
import {Container} from 'react-bootstrap';

import "../assets/css/GameDisplay.css";

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Crop75Icon from '@mui/icons-material/Crop75';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {useState} from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import {List, Chip, ListItem, ListItemButton, ListItemIcon, ListItemText,
   Button, Accordion, AccordionDetails, AccordionSummary} from '@mui/material';

import game_img from '../assets/img/test/game_test.avif';
import Providers from '../components/Home/Providers';
import HeaderPicks from '../components/Home/HeaderPicks';
import OtherGames from '../components/Home/OtherGames';
import useMediaQuery from '@mui/material/useMediaQuery';

const MenuTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="bottom" arrow  {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 0
  },
}));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
}));

const MenuTitle = ({selectedBalance, setSelectedBalance}) => {

  return(
      <List className="list_menu_nav">
          {balances.map((b, index) => (
          <ListItem 
          key={index}
          disablePadding 
          sx={{ display: 'block' }}
          onClick={() => setSelectedBalance(b)}
          >
              <ListItemButton
                  sx={{
                  justifyContent: 'initial',
                  }}
                  className={selectedBalance ? selectedBalance?.id === b.id ? 'selected_menu_nav' : '' : ''}
              >
                  <ListItemIcon
                  sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                  }}
                  className="dropdown_curren_flex"
                  >
                      <img src={b.img} alt="currency"/>
                  </ListItemIcon>
                  <ListItemText primary={b.name} />
              </ListItemButton>
          </ListItem>
          ))}
      </List>
  )
}

const iconsLeft = [
  {id: 0, name: 'fullscreen'},
  {id: 1, name: 'resize'},
  {id: 2, name: 'favourites'},
]

const balances = [
{id: 0, name: 'EUR', img: '/static/currencies_real/dollar.png'},
{id: 1, name: 'USD', img: '/static/currencies_real/euro.png'},
]

const tags = [
  {id: 0, name: 'Bonus Buy'},
  {id: 1, name: 'Candy'},
  {id: 2, name: 'Cascading'},
  {id: 3, name: 'Pragmatic Play'},
  {id: 4, name: 'Recommended Games'},
  {id: 5, name: 'Slots'}
]


export default function GameDisplay(){
    const {game_name} = useParams();
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:600px)');

    const [selectedIcon, changeSelIcon] = useState(-1);
    const [checkedReal, setCheckedReal] = useState(false);
    const [selectedBalance, setSelectedBalance] = useState(balances[0]);
    const [openBalances, setOpenBalances] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [fullscreen, setFullscreen] = useState(false)

    const changeSelectedIcon = (id) => {
        if(selectedIcon !== id){
            changeSelIcon(id)
        }else{
            changeSelIcon(-1)
        }
    }

    const changeSelectedBalance = (balance) => {
      if(balance !== selectedBalance){
        setSelectedBalance(balance);
      }
      setOpenBalances(false)
    }

    const handleCloseMenu = () => {
      setOpenBalances(false);
    };
    return(
        <div className="main_page">
            <Container id="game_main_1" className={selectedIcon === 1 ? "game_display_main_cinema game_display_main" : "game_display_main"}>
                <div className="game_display_choose">
                  <div className="game_display_before_choose">
                        {matches &&
                        <>
                          <div className="game_display_info">
                            <div>
                                <img src={game_img} alt="game" />
                            </div>
                            <div>
                              <div className="game_info_title">Sugar Rush 1000</div>
                              <div className="game_info_provid">Pragmatic Play</div>
                            
                              <div className="game_info_tags">
                                {tags.map(t =>
                                  <Chip onClick={() => navigate('/play/group/' + t.name.toLowerCase().replaceAll(' ','-'))} key={t.id} label={t.name} />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="switch_games">
                            <FormControlLabel
                              className="play_switch"
                              control={<IOSSwitch onChange={(e) => setFullscreen(e.target.checked)} checked={fullscreen} sx={{ m: 1 }} />}
                              label=""
                          />
                          <Typography>Play Fullscreen</Typography>
                          </div>
                        </>
                        }
                      <div className="dropdown_curren">
                        <div className="pe-2">
                          Balance in
                        </div>
                        <MenuTooltip 
                        PopperProps={{
                            disablePortal: true,
                        }}
                        open={openBalances} 
                        onClose={handleCloseMenu} 
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                        <MenuTitle
                        selectedBalance={selectedBalance}
                        setSelectedBalance={changeSelectedBalance}
                        />
                        }>
                          {selectedBalance &&
                            <div onClick={() => setOpenBalances(!openBalances)} className="dropdown_curren_flex">
                              <img alt="currency" src={selectedBalance?.img} />
                              <span>{selectedBalance?.name}</span>
                              {openBalances ? <ExpandLessIcon className="hover_btn_game"/> : <ExpandMoreIcon className="hover_btn_game"/>}
                            </div>
                          }
                        </MenuTooltip>
                      </div>

                      <div className="btns_game_displ">
                          <Button variant="contained" onClick={() => setCheckedReal(true)}><PlayArrowIcon/> <div> Real Play </div></Button>
                          <Button variant="contained" onClick={() => setCheckedReal(false)}><PlayArrowIcon/> <div> Fun Play </div></Button>
                      </div>

                  </div>
                </div>

                <div className="game_display_footer">
                    {matches && <div></div>}
                    <div className="game_icons_left">
                        {iconsLeft.map(i =>
                            <div onClick={() => changeSelectedIcon(i.id)} className={selectedIcon === i.id ? 'selected_icon_game' : ''} key={i.id}>
                                {i.id === 0 && !matches ? <FullscreenIcon/> : 
                                (i.id === 1 && !matches) ? <Crop75Icon/> :
                                (i.id === 2 && i.id === selectedIcon) ? <StarIcon/> : 
                                i.id === 2 ? <StarBorderIcon/> : <></>
                                }
                            </div>
                        )}
                    </div>
                    {!matches &&
                    <div className="switch_games">
                        <Typography className={checkedReal ? "" : "selected_switch" }>Fun Play</Typography>
                        <FormControlLabel
                            className="play_switch"
                            control={<IOSSwitch onChange={(e) => setCheckedReal(e.target.checked)} checked={checkedReal} sx={{ m: 1 }} />}
                            label=""
                        />
                        <Typography className={checkedReal ? "selected_switch" : "" }>Real Play</Typography>
                    </div>
                    }
                </div>
            </Container>
            {game_name && 
            <Container style={{minHeight: 'fit-content'}} className="game_display_main game_display_main_info">
              <Accordion expanded={expanded} className="accordion_config">
                <AccordionSummary
                  expandIcon={<DragIndicatorIcon onClick={() => {setExpanded(!expanded)}} className="icon_more_info_game"/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="game_display_info">
                    <div>
                      {!matches &&
                        <img src={game_img} alt="game" />}
                    </div>
                    <div>
                      <div className="game_info_title">Sugar Rush 1000</div>
                      <div className="game_info_provid" onClick={() => navigate('/play/group/pragmatic-play')}>Pragmatic Play</div>
                      {!matches &&
                      <div className="game_info_tags">
                        {tags.map(t =>
                          <Chip onClick={() => navigate('/play/group/' + t.name.toLowerCase().replaceAll(' ','-'))} key={t.id} label={t.name} />
                        )}
                      </div>
                      }
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="acc_details_config">
                  <p>
                  Sharpen your sweet tooth; we’re going in for more treats. <br/><br/>
                  Sugar Rush 1000 is part of Pragmatic Play’s popular Sugar Rush series and follows Sugar Rush and Sugar Rush Xmas. 
                  The title is played on a 7x7 grid featuring the scatter pays mechanic and cascading reels. <br/><br/>
                  Sugar Rush 1000 delivers high volatility gameplay and a max prize of 25,000 times your bet. <br/><br/>
                  Play today at Stake Casino. <br/>
                  </p>
                  <h4>How to Play Sugar Rush 1000 & Gameplay</h4>
                  <p>
                  The Sugar Rush 1000 online slot machine follows standard grid slot gameplay with the popular cascading reels mechanic that allows for multiple wins from a single spin of the reels. <br/><br/>
                  If you’re a new player, visit the Stake blog for tips on how to play slots . If you have an account on Stake.com , you can try demo games first to get used to all the mechanics. You can also read more about the difference between offline and online slots and the RNG technology is our huge range of slots .
                  <br/>
                  </p>
                </AccordionDetails>
              </Accordion>
            </Container>
            }

            <Container className="overflow_containers" style={{paddingTop: '0rem'}}>
                 <Providers />
            </Container>

            <Container style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
                <OtherGames text="Recommended" />
            </Container>
        </div>
    )
}