import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

import id from '../../assets/img/profile/id.png';
import id_back from '../../assets/img/profile/id-back.png';
import { setWalletSubmitted } from '../../redux/reducers/WalletReducer';
import axiosInstance from '../../redux/axios';
import { setUser } from '../../redux/reducers/UserReducer';

const options = ["ID card", "Driver license", "Passport", "Other"]
export default function WalletId({close, settings}){
    const {user} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [idType, setIdType] = useState('ID card');
    const [frontImg, setFrontImg] = useState(null);
    const [backImg, setBackImg] = useState(null);
    const [passport, setPassportImg] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        setError('')
    }, [frontImg, backImg, passport]);

    const uploadClick = (id) => {
        if(!user?.is_step_two_completed){
        document.getElementById(id).click();
        }
    }

    const submit = async () => {
        if(!user?.is_step_two_completed){
            if(idType !== 'Passport'){
                if(!frontImg?.file && !backImg?.file){
                    setFrontImg({error: 'No file added'})
                    setBackImg({error: 'No file added'})
                }else if(!backImg?.file){
                    setBackImg({error: 'No file added'})
                }else if(!frontImg?.file){
                    setFrontImg({error: 'No file added'})
                }else{
                    try{
                        const formData = new FormData();
                        const type = options.indexOf(idType)
                        if(type !== -1){
                            formData.append('document_type', type+1);
                            console.log(type+1)
                        }
                        formData.append('document_front_side', frontImg?.file);
                        formData.append('document_back_side', backImg?.file);
                        const response = await axiosInstance.post('users/'+user?.id+'/verify/step-two', formData);
                        console.log(response)

                        if(response.data?.data){
                            dispatch(setUser(response?.data?.data))
                        }
                    }catch(e){
                        if(e.response?.data?.message){
                            setError(e.response?.data?.message);
                        }
                        if(e.response?.data?.error){
                            setError(e.response?.data?.error);
                        }  
                    }
                }
            }else{
                if(!passport?.file){
                    setPassportImg({error: 'No file added'})
                }else{
                    try{
                        const formData = new FormData();
                        formData.append('document_type', 3);
                       
                        formData.append('document_front_side', frontImg?.file);
                        formData.append('document_back_side', frontImg?.file);
                        const response = await axiosInstance.post('users/'+user?.id+'/verify/step-two', formData);
                        
                        if(response.data?.data){
                            dispatch(setUser(response?.data?.data))
                        }
                    }catch(e){
                        if(e.response?.data?.message){
                            setError(e.response?.data?.message);
                        }
                        if(e.response?.data?.error){
                            setError(e.response?.data?.error);
                        }  
                    }
                }
            }
        }
    }
    return(
        <Form>
           <div className="form_modal_in"> 
                <p className="pt-3">This step will unlock more capabilities such as higher betting limits and enhanced account security.</p>

                <Form.Group data-bs-theme="dark"  className="mt-4 mb-3" controlId="details.id">
                    <Form.Label>Document type <span className="form_required">*</span></Form.Label>
                    <Form.Select 
                    disabled={user?.is_step_two_completed}
                    value={idType}
                    onChange={(e) => setIdType(e.target.value)}
                    aria-label="Identification type">
                        {options.map(m =>  
                            <option key={m} value={m}>{m}</option>
                        )}
                    </Form.Select>    
                </Form.Group>
                <Paper className="paper_id" elevation={4}>
                    <div className="p-2">
                        Following file types are accepted: .png, .jpg, .pdf
                    </div>
                </Paper>
                {idType !== "Passport" && ((user?.is_step_two_completed && !settings) || !user?.is_step_two_completed) &&
                <Grid container spacing={{ xs: 0, sm: 1, md: 2 }}>
                <Grid item xs={6}>
                    <Form.Group data-bs-theme="dark"  className="mt-4 full_height" controlId="details.first_name">
                        <Form.Label>Front side <span className="form_required">*</span></Form.Label>
                        {frontImg?.img &&
                            <div className="uploaded_container">
                                <img style={{width: '100%'}} src={frontImg?.img} alt="id" />
                                <div className="floated_exit" onClick={() => setFrontImg(null)}><CloseIcon/></div>
                            </div>
                        }
                        {!frontImg?.img &&
                            <>
                                <input type="file" style={{display: 'none'}} onChange={(e) => {
                                    if (e.target.files && e.target.files[0]) {
                                        let img = e.target.files[0];
                                        setFrontImg({img: URL.createObjectURL(img), file: img});
                                    }
                                }} id="front_side"></input>
                                <div onClick={() => uploadClick('front_side')} className="upload_container">
                                    <img style={{width: '100%'}} src={id} alt="id" />
                                    <Button variant="contained" className="submit_btn">Upload Front Side</Button>
                                </div>
                                {frontImg?.error &&
                                    <p className="error_wallet"><ErrorOutlineIcon/>{frontImg?.error}</p>
                                }
                            </>
                        }
                        
                    </Form.Group>
                </Grid>
                <Grid item xs={6}>
                    <Form.Group className="mt-4" controlId="details.last_name">
                        <Form.Label>Back side <span className="form_required">*</span></Form.Label>
                        {backImg?.img &&
                            <div className="uploaded_container">
                                <img style={{width: '100%'}} src={backImg?.img} alt="id" />
                                <div className="floated_exit" onClick={() => setBackImg(null)}><CloseIcon/></div>
                            </div>
                        }
                        {!backImg?.img &&
                            <>
                            <input type="file" style={{display: 'none'}} id="back_side" onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                        let img = e.target.files[0];
                                        setBackImg({img: URL.createObjectURL(img), file: img});
                                    }
                                }}></input>
                            <div onClick={() => uploadClick('back_side')} className="upload_container">
                                <img style={{width: '100%'}} src={id_back} alt="id back" />
                                <Button variant="contained" className="submit_btn">Upload Back Side</Button>
                            </div>
                            {backImg?.error &&
                                <p className="error_wallet"><ErrorOutlineIcon/>{backImg?.error}</p>
                            }
                            </>
                        }
                       
                    </Form.Group>
                </Grid>
                </Grid>
                }
                {idType === "Passport" && ((user?.is_step_two_completed && !settings) || !user?.is_step_two_completed) &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form.Group className="mt-4" controlId="details.first_name">
                            <Form.Label>Passport <span className="form_required">*</span></Form.Label>
                            {passport?.img &&
                                <div className="uploaded_container">
                                    <img style={{width: '40%'}} src={passport?.img} alt="id" />
                                    <div className="floated_exit" onClick={() => setPassportImg(null)}><CloseIcon/></div>
                                </div>
                            }
                            {!passport?.img &&
                            <>
                            <input type="file" style={{display: 'none'}} onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                    let img = e.target.files[0];
                                    setPassportImg({img: URL.createObjectURL(img), file: img});
                                }
                                }} id="passport"></input>
                            <div onClick={() => uploadClick('passport')} className="upload_container">
                                <img style={{width: '45%'}} src={id} alt="id" />
                                <Button variant="contained" className="submit_btn">Upload Passport</Button>
                            </div>
                            {passport?.error &&
                                <p className="error_wallet"><ErrorOutlineIcon/>{passport?.error}</p>
                            }
                            </>
                            } 
                        </Form.Group>
                    </Grid>
                </Grid>
                }
            </div>   

            <Button disabled={user?.is_step_two_completed && settings} variant="contained" onClick={() => submit()} className="submit_btn full_width mt-1">Submit</Button>
            {error !== '' && <div className="error_p pt-2">{error}</div>}
        </Form>
    )
}