
import { Container } from 'react-bootstrap';
import { Grid } from '@mui/material';
import { useState } from 'react';
import FAQDetails from './FAQDetails';
import SideMenuFAQ from './SideMenuFAQ';

const menuItems= [
    {id: 0, name: 'General', questions: [
        {id: 0, question: "Why is Casino's VIP program the best?", answer: "Casino's VIP program is often rated as one of the best online casino experiences due to the amount of bonuses we give out to players.<br/><br/> Additionally, our award winning support team is online 24/7 to answer any questions you have. We have the highest customer satisfaction rate out of many online casinos where we have amassed a community of some of the most passionate gamblers across the globe."},
        {id: 1, question: "How much has Casino given out in bonuses?", answer: "We have given over $1 Billion in bonuses. This is primarily the reason we have the best VIP program online. <br/> We truly believe in rewarding our players for their gameplay and loyalty."},
        {id: 2, question: "How do I enter the $75,000 weekly raffle?", answer: "To get one ticket to enter the raffle, you must wager $1000 on Stake.com. The more you wager, the more tickets you get, which increases your chances of winning."},
    ]},
    {id: 1, name: 'Benefits', questions: [
        {id: 0, question: "Why is Casino's VIP program the best?", answer: "Casino's VIP program is often rated as one of the best online casino experiences due to the amount of bonuses we give out to players.<br/><br/> Additionally, our award winning support team is online 24/7 to answer any questions you have. We have the highest customer satisfaction rate out of many online casinos where we have amassed a community of some of the most passionate gamblers across the globe."},
        {id: 1, question: "How much has Casino given out in bonuses?", answer: "We have given over $1 Billion in bonuses. This is primarily the reason we have the best VIP program online. <br/> We truly believe in rewarding our players for their gameplay and loyalty."},
    ]},
    {id: 2, name: 'VIP Host', questions:  [
        {id: 0, question: "Why is Casino's VIP program the best?", answer: "Casino's VIP program is often rated as one of the best online casino experiences due to the amount of bonuses we give out to players.<br/><br/> Additionally, our award winning support team is online 24/7 to answer any questions you have. We have the highest customer satisfaction rate out of many online casinos where we have amassed a community of some of the most passionate gamblers across the globe."},
        {id: 1, question: "How much has Casino given out in bonuses?", answer: "We have given over $1 Billion in bonuses. This is primarily the reason we have the best VIP program online. <br/> We truly believe in rewarding our players for their gameplay and loyalty."},
        {id: 2, question: "How do I enter the $75,000 weekly raffle?", answer: "To get one ticket to enter the raffle, you must wager $1000 on Stake.com. The more you wager, the more tickets you get, which increases your chances of winning."},
        {id: 3, question: "Where can I find the Casino Telegram Channel?", answer: "The Casino Telegram channel is (@StakeCasino)."},
        {id: 4, question: "Where can I find the Casino VIP Telegram channel?", answer: "Once you reach Bronze, you can ask live support to be added to the Stake VIP Telegram Channel. <br/> Alternatively, you can find more information about this by joining the Stake Telegram channel (@StakeCasino)"},
    ]},
]


export default function FAQComponent(){
    const [selected, setSelected] = useState(menuItems[0]?.name);
    return(
        <Container className="pt-5">
            <div className="title_rankclub">
                Frequently Asked Questions
            </div>
            <Grid container spacing={{ xs: 2, sm: 0, md: 0 }}>
                <Grid item xs={12} sm={3} md={3}>
                    <SideMenuFAQ menuItems={menuItems} selected={selected} setSelected={setSelected}/>
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                    <FAQDetails selected={selected} menuItems={menuItems.filter(x => {return x.name.toLowerCase() === selected?.toString().toLowerCase()})} />
                </Grid>
            </Grid>
        </Container>
    )
}