import { Form } from 'react-bootstrap';
import {FormControl, OutlinedInput,InputAdornment, ButtonGroup, Button } from '@mui/material';

const defaultVal = 0;
export default function UserTransferPlayer({amountCredits, setAmountCredits, player, setPlayer}){

    const setAmount = (value) => {
        if(value < 0){
            setAmountCredits(0)
        }else{
            setAmountCredits(value);
        }
    }
    return(
        <div>
            <div className="display_column full_width pb-3 pt-3">
                <Form.Label>
                    <div className="display_spacebetween">
                    <div>User ID or username</div>
                    <div></div>
                    </div>
                </Form.Label>   
                <ButtonGroup aria-label="Basic example">
                    <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="text"
                        value={player}
                        onChange={(e) => setPlayer(e.target.value)}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                        placeholder="ex. 123456"
                    />
                    </FormControl>
                </ButtonGroup>
            </div>
            <div className="display_column full_width pb-3">
                <Form.Label>
                    <div className="display_spacebetween">
                    <div>Amount</div>
                    <div>$0.00</div>
                    </div>
                </Form.Label>   
                <ButtonGroup aria-label="Basic example">
                    <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="number"
                        value={amountCredits}
                        onChange={(e) => setAmount(e.target.value)}
                        min={defaultVal}
                        endAdornment={<InputAdornment position="end">Credits</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                    </FormControl>
                    <Button className="modal_btn" variant="contained">MAX</Button>
                </ButtonGroup>
            </div>
        </div>
    )
}