import {Modal, Button, Form} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import axiosInstance from '../../redux/axios';
import { useDispatch } from 'react-redux';
import { setAccessToken, setUser } from '../../redux/reducers/UserReducer';

export default function SignIn(props){
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const { Formik } = formik;
    const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);


    const validationSchema =  yup.object().shape({
        email: yup.string().required().email(),
        password: yup.string().required().min(6)
    })

    const initialValues = {
        email: '',
        password: ''
    }

    return(
    <Modal
    {...props}
    size="md"
    aria-labelledby="title_signin"
    centered
    data-bs-theme="dark"
    >
        <Modal.Header className="header_auth" closeButton>
          <Modal.Title id="title_signin">
            Sign In
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
            <Formik
            validationSchema={validationSchema}
            validateOnChange={validateAfterSubmit}
            validateOnBlur={validateAfterSubmit}
            onSubmit={async (values) => {
                if(validationSchema.isValidSync(values)){
                    try{
                        const formData = new FormData();
                        formData.append('email', values.email);
                        formData.append('password', values.password)

                       
                        const response = await axiosInstance.post('auth/login',formData)
                    
                        if(response?.data?.access_token){
                            await dispatch(setAccessToken(response?.data?.access_token))
                        }

                        if(response?.data?.user){
                            dispatch(setUser(response?.data?.user))
                            props.onHide()
                        }else{
                            const response1 = await axiosInstance.get('auth/me');
                            if(response1.data?.data){
                                dispatch(setUser(response1?.data?.data))
                                props.onHide()
                            }
                        }


                    }catch(e){
                        if(e.response?.data?.message){
                            setError(e.response?.data?.message);
                        }
                        if(e.response?.data?.error){
                            setError(e.response?.data?.error);
                        }
                    }
                }else{
                    setError('Fill in the form')
                }
            }}
            
            initialValues={initialValues}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks, no-undef
                    useEffect(() => {
                        setError('')
                    }, [values]);

                    return (
                    <Form data-bs-theme="dark" noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mt-2" controlId="auth.email">
                            <Form.Label>Email <span className="form_required">*</span></Form.Label>
                            <Form.Control  
                                className="input_settings" 
                                type="email" 
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.email && !errors.email}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-2" controlId="auth.password">
                            <Form.Label>Password <span className="form_required">*</span></Form.Label>
                            <Form.Control  
                                className="input_settings" 
                                type="password" 
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                                isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Button className="submit_btn full_width" style={{paddingTop: '.8rem', paddingBottom: '.8rem'}}  onClick={() => {
                            setValidateAfterSubmit(true)
                        }} type="submit">Sign In</Button>
                         {error !== '' && <div className="error_p pt-2">{error}</div>}
                    </Form>
                )
                }}
            </Formik>
            <div className="display_flex_center pt-4 pb-4">
                <div className="or_auth">
                    <span>OR</span>
                </div>
            </div>

            <div className="display_flex_center">
                <div className="sub_auth" onClick={() => {props.onHide();props.setForgotPass(true)}}>Forgot Password</div>
            </div>

            <div className="display_flex_center pt-4">
                <div className="two_parts_auth">
                    Don’t have an account? <span onClick={() => {props.onHide();props.setRegister(true);}}> Register an Account </span>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    )
}