
import {useNavigate} from "react-router-dom";

export default function SideMenuSettings({menuItems, selected, setSelected}){
    const navigate = useNavigate();
   
    return(
        <div className="transaction_sidemenu_cont">
            {menuItems.map(m => 
                <div 
                onClick={() => {setSelected(m.name); navigate('/settings/'+m.name.toLowerCase().replaceAll(' ', '-'))}} 
                className={selected === m.name ? "el_trans_sidemenu_selected" : "el_trans_sidemenu"} 
                key={m.id}>
                    {m.name}
                </div>
            )}
        </div>
    )
}