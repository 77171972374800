import StyleIcon from '@mui/icons-material/Style';

export default function RankImprove({id, name, desc}){
    return(
        <div style={{width: 'fit-content'}} className="display_spacebetween pt-2">
            <div className="icon_rank_cont">
                {id===0 && 
                <StyleIcon/>}
            </div>
            <div className="ps-2">
                <div className="name_rank_cont">{name}</div>
                <div className="desc_rank_cont">{desc}</div>
            </div>
        </div>
    )
}