import {Modal} from 'react-bootstrap';
import WalletIcon from '@mui/icons-material/Wallet';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {ButtonGroup, FormControl, Button,Breadcrumbs, Typography} from "@mui/material";
import {useState} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Form } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SearchIcon from '@mui/icons-material/Search';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    InputAdornment,
    OutlinedInput,
    Grid
} from '@mui/material';
import QRCode from "react-qr-code";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const MenuTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="bottom" arrow {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#161316',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxHeight: '40vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxWidth: 400,
        minWidth: 400,
    },
}));

const MenuTitle = ({currencies, setShowMore, changeCurrency, currency}) => {
    const [search, setSearch] = useState('');
  
    return(
        <List className="list_menu_nav">
           <div>
              <TextField
              dense
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="search_currencies"
              sx={{ m: 1, width: '96%' }}
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                endAdornment: 
                <InputAdornment position="end">
                    <CloseIcon onClick={() => {setSearch('')}}/>
                </InputAdornment>
              }}
              placeholder="Search currency"
              />
            </div>
            {currencies.filter(x=> {return x.value.toLowerCase().includes(search.toLowerCase())}).map((c, index) => (
            <ListItem 
            key={index}
            disablePadding 
            sx={{ display: 'block' }}
            onClick={() => {setShowMore(false);changeCurrency(c)}}>
                <ListItemButton
                    sx={{
                    justifyContent: 'initial',
                    }}
                    className={currency?.id === c.id ? 'selected_menu_nav' : ''}
                >
                    <ListItemIcon
                    sx={{
                        minWidth: 0,
                        justifyContent: 'center',
                    }}
                    >
                        
                    </ListItemIcon>
                    <ListItemText primary={<div key={c.value} className="currency-option">
                              <img src={c.symbol} alt="token" />
                              <span className="ps-2">{c.value}</span>
                          </div>} />
                </ListItemButton>
            </ListItem>
            ))}
        </List>
    )
  }

const currencies = [
    {id: 0, amount: '0.000000', value: 'BTC', fullName: 'Bitcoin', symbol: '/static/currencies/Bitcoin.svg'},
    {id: 1, amount: '0.000000', value: 'ETH', fullName: 'Ethereum',  symbol: '/static/currencies/eth.png' },
    {id: 2, amount: '0.000000', value: 'LTC', fullName: 'Litecoin',symbol: '/static/currencies/ltc.png' },
    {id: 3, amount: '0.000000', value: 'USDT', fullName: 'USD Tether', symbol: '/static/currencies/usdt.png' },
    {id: 4, amount: '0.000000', value: 'DOGE', fullName: 'Dogecoin ', symbol: '/static/currencies/doge.png' },
    {id: 5, amount: '0.000000', value: 'BNB', fullName: 'Binance Coin', symbol: '/static/currencies/bnb.png' },
    {id: 6, amount: '0.000000', value: 'AVAX', fullName: 'Avalanche', symbol: '/static/currencies/avax.png' },
    {id: 7, amount: '0.000000', value: 'MATIC', fullName: 'MATIC Coin', symbol: '/static/currencies/matic.png' },
    {id: 8, amount: '0.000000', value: 'USDC', fullName: 'USD Coin', symbol: '/static/currencies/usdc.png' },
]
const walletInfo = {
    currencies: [
        {id: 0, currency: currencies[1], amount: '0.06', dollars: 2973.83},
        {id: 1, currency: currencies[7], amount: '123', dollars: 83.50}
    ],
    totalDollars: 3057.33
}

export default function Wallet(props){
    const [activeStep, setActiveStep] = useState('Home');
    const [showMore, setShowMore] = useState(false);
    const [currency, setCurrency] = useState(currencies[0]);
    const [seeBalance, changeSeeBalance] = useState(true);
    const [amountWithdraw, changeWithdrowAmount] = useState('');
    const [withdrawAddress, changeWithdrawAddress] = useState('');
    
    const address = '0x3192479c96011943fsdC4f558846C37900d84dd';
    const defaultVal = '0.000000';
    const onHide = props?.onHide;
    const show = props?.show;
    const setShowVault = props?.setShowVault;

    const handleCloseMenu = () => {
        setShowMore(false)
    }

    const changeCurrency = (e) => {
        setCurrency(e)
    }
  
    const matches = useMediaQuery('(max-width:600px)');
    return(
        <Modal
        onHide={onHide}
        show={show}
        fullscreen={matches}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-bs-theme="dark"
      >
        <Modal.Header className="header_wallet" closeButton>
          <Modal.Title id="title_header_wallet">
                {activeStep === 'Home' ? <WalletIcon/> : <></>} 
                {activeStep === 'Home' ? "Wallet" : (
                   <Breadcrumbs  aria-label="breadcrumb">
                        <div className="icon_text" onClick={() => setActiveStep("Home")}>
                            <ArrowBackIosIcon/> Wallet
                        </div> 
                        <Typography variant="p" color="text.primary">{activeStep}</Typography>
                    </Breadcrumbs>
                )}
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body style={activeStep === 'Home' ? {display: 'flex', flexDirection: 'column', flexWrap: 'noWrap'} : {}}>
            {activeStep === 'Home' &&
                <>
                    {walletInfo.totalDollars <= 0 &&
                    <div>
                        <h4>Your wallet is currently empty.</h4>
                        <p>
                            Make a deposit via crypto or local currency if it's available in your region. Alternatively, you can buy crypto via Moonpay.
                        </p>

                        <div className="display_flex_center">
                            <ButtonGroup variant="contained" className="wallet_btngroup" aria-label="wallet_btn_group" >
                                <Button onClick={() => {setActiveStep('Buy Crypto')}} variant="contained" className="wallet_btn1">Buy Crypto</Button>
                                <Button onClick={() => {setActiveStep('Deposit')}} variant="contained" className="wallet_btn2">Deposit</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    }
                    {walletInfo.totalDollars > 0 &&
                    <>
                        <div style={{flex: 1}}>
                            <div className="display_start">
                                <div className="icon_text_modal helper_modal_p pb-1 pe-2">Estimated balance</div>
                                <div onClick={() => changeSeeBalance(!seeBalance)}>{seeBalance ? <VisibilityIcon className="icon_modal" /> : <VisibilityOffIcon className="icon_modal" />}</div>
                            </div>
                            <div className="balance_modal">
                            {seeBalance ? <span>${walletInfo.totalDollars?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <span>USD</span></span> : <span>******</span> }  
                            </div>
                            <div className="currencies_display">
                                <div className="display_spacebetween">
                                    <div>Your currencies</div>
                                    <div>Value</div>
                                </div>

                                <div className="divider" />

                                <div className="wallet_info_cont">
                                {walletInfo.currencies && walletInfo.currencies.map(c => 
                                    <div className="display_spacebetween" key={c.id}>
                                        <div className="display_start">
                                            <div className="pe-2">
                                                <img style={{width: '1.5rem'}} src={c.currency.symbol} alt='symbol' />
                                            </div>
                                            <div>
                                                <div className="text_white text_bolder">{c.currency.value}</div>
                                                <div>{c.currency.fullName}</div>
                                            </div>
                                        </div>
                                        <div style={{textAlign: 'end'}}>
                                            {seeBalance ?
                                                <>
                                                <div className="text_white text_bolder">
                                                    {c.amount}
                                                </div>
                                                <div>
                                                    ${c.dollars} USD
                                                </div>
                                                </>
                                                :
                                                <span>*******</span>
                                            }
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>

                        <div className="pt-5 mt-4 mb-2">
                            <Grid container spacing={1}>
                                <Grid className="display_flex_center" item xs={6}>
                                    <Button onClick={() => {setActiveStep('Deposit')}} className="btns_wallet" variant="contained">Deposit</Button>
                                </Grid>
                                <Grid className="display_flex_center" item xs={6}>
                                    <Button onClick={() => {setActiveStep('Withdraw')}}  className="btns_wallet" variant="contained">Withdraw</Button>
                                </Grid>
                                <Grid className="display_flex_center"  item xs={6}>
                                    <Button onClick={() => {setActiveStep('Buy Crypto')}}  className="btns_wallet grayed_btns" variant="contained">Buy Crypto</Button>  
                                </Grid>
                                <Grid className="display_flex_center"  item xs={6}>
                                    <Button onClick={(e) => {onHide(e);setShowVault(true);}} className="btns_wallet grayed_btns" variant="contained">Get Credits</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                    }
                </>
            }
            {activeStep === 'Deposit' &&
                <div>
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                        <div>
                            <MenuTooltip 
                            PopperProps={{
                                disablePortal: true,
                            }}
                            open={showMore} 
                            onClose={handleCloseMenu} 
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                            <MenuTitle currencies={currencies} setShowMore={setShowMore} changeCurrency={changeCurrency} currency={currency}/>
                            }>
                                <div onClick={() => {setShowMore(!showMore)}} className="currencty_displayer">
                                    {currency &&
                                    <div className="currency-option">
                                        <img src={currency.symbol} alt="token" />
                                        <span className="ps-2">{currency.value}</span>
                                    </div>
                                    }
                                    <div>
                                    {showMore ? <ExpandLessIcon/> : <ExpandMoreIcon/>} 
                                    </div>
                                </div>
                            </MenuTooltip>
                        </div>
                    </ClickAwayListener>

                    <div className="currency_container pt-3" style={{width: '100%'}}>
                        <Form.Label className="text-gray">Your {currency?.value} deposit address</Form.Label>   

                        <OutlinedInput
                        className="input_modal input_modal_dense"
                        style={{width: '100%', fontSize: '.9rem', paddingTop: '0'}}
                        type="text"
                        readOnly
                        value={address}
                        endAdornment={<InputAdornment position="end"><ContentCopyIcon style={{cursor: 'pointer'}}/></InputAdornment>}
                        aria-describedby="adress"
                        inputProps={{
                        'aria-label': 'adress',
                        }}
                        />
                    </div>

                    <div style={{paddingTop: '1rem'}}>
                        <div style={{ height: "auto", display: 'flex', margin: "auto", width: "100%" }}>
                            <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "40%",padding: '2%', backgroundColor: 'white', borderRadius: '10px', margin: 'auto', width: "50%" }}
                            value={address}
                            viewBox={`0 0 256 256`}
                            />
                        </div>
                        <p className="text-center text-gray pt-3 desc_test">Only send {currency?.value} to this address, 2 confirmations required.</p>
                    </div>
                </div>
            }

            {activeStep === 'Withdraw' &&
                <div>
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                        <div>
                            <MenuTooltip 
                            PopperProps={{
                                disablePortal: true,
                            }}
                            open={showMore} 
                            onClose={handleCloseMenu} 
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                            <MenuTitle currencies={currencies} setShowMore={setShowMore} changeCurrency={changeCurrency} currency={currency}/>
                            }>
                                <div onClick={() => {setShowMore(!showMore)}} className="currencty_displayer">
                                    {currency &&
                                    <div className="currency-option">
                                        <img src={currency.symbol} alt="token" />
                                        <span className="ps-2">{currency.value}</span>
                                    </div>
                                    }
                                    <div>
                                    {showMore ? <ExpandLessIcon/> : <ExpandMoreIcon/>} 
                                    </div>
                                </div>
                            </MenuTooltip>
                        </div>
                    </ClickAwayListener>

                    <div className="display_column full_width pt-3">
                        <Form.Label className="ps-1">
                            <div className="display_spacebetween">
                            <div>Amount to Withdraw <span className="form_required">*</span></div>
                            <div>$0.00</div>
                            </div>
                        </Form.Label>   
                        <ButtonGroup aria-label="Basic example">
                            <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                className="input_modal"
                                type="number"
                                value={amountWithdraw}
                                placeholder={defaultVal}
                                onChange={(e) => changeWithdrowAmount(e.target.value)}
                                min={defaultVal}
                                endAdornment={<InputAdornment position="end">{currency ? <img className="img_token" src={currency.symbol} alt="token" /> : <></>}</InputAdornment>}
                                aria-describedby="withdraw_amount"
                                inputProps={{
                                'aria-label': 'amount',
                                }}
                            />
                            </FormControl>
                            <Button className="modal_btn" variant="contained">MAX</Button>
                        </ButtonGroup>
                    </div>

                    <div className="display_column full_width pt-3">
                        <Form.Label className="ps-1">
                            <div className="display_spacebetween">
                                <div>Withdraw to <span className="form_required">*</span></div>
                            </div>
                        </Form.Label> 

                        <ButtonGroup aria-label="Basic example">
                            <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                className="input_modal"
                                type="text"
                                value={withdrawAddress}
                                onChange={(e) => changeWithdrawAddress(e.target.value)}
                                min={defaultVal}
                                aria-describedby="withdraw-address"
                                inputProps={{
                                'aria-label': 'address',
                                }}
                                placeholder={"Enter your " + (currency ? currency.value : '') + " address"}
                            />
                            </FormControl>
                            <Button className="modal_btn" variant="contained"><ContentCopyIcon sx={{width: '1rem'}} /></Button>
                        </ButtonGroup>
                    </div>

                    <div className="ps-1 pt-2 pb-4">
                        <div className="icon_text_modal helper_modal_p" style={{textAlign: 'start'}}>
                            Minimum withdraw amount is 0.00070433 {currency ? <img className="img_token pb-1" src={currency.symbol} alt="token" /> : <></>}. Your withdrawal
                            will have 0.00067447 {currency ? <img className="img_token pb-1" src={currency.symbol} alt="token" /> : <></>} subtracted from your remaining balance to cover for
                            fee required to process the transaction.
                        </div>
                    </div>

                    <div className="pb-2">
                        <Button className="btns_wallet" variant="contained">Withdraw</Button>
                    </div>
                </div>
            }
        </Modal.Body>
      </Modal>
    )
}