import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link} from '@mui/material';

import AutoCompleteSearch from '../../components/Home/AutoCompleteSearch';

const recent = []

export default function Recent(){
 
    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div> Recent </div>
                   <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Play
                        </Link> 
                        <Typography color="text.primary">Recent</Typography>
                    </Breadcrumbs>
                </Container>
            </div>
            <Container style={{padding: '1rem', paddingTop: '2rem'}}>
                <div className="mobile_search_home">
                    <AutoCompleteSearch />
                </div>

               <div>
                {!recent.length && 
                    <div className="display_search_autocomplete display_nonefound_mobile"> No recent games to display.</div>
                }
               </div>
            </Container>
        </div>
    )
}