import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   accessToken: null,
   user: null
};

const UserSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        logOutUser: (state) => {
            state.accessToken = null;
            state.user = null;
        },
    }
});

export const {
    setUser,
    setAccessToken,
    logOutUser
} = UserSlice.actions;

export default UserSlice.reducer;