
import HeaderPicks from './HeaderPicks';
import {useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';

export default function Providers(){
    const [arrowLeft, setArrowLeft] = useState(false);
    const [arrowRight, setArrowRight] = useState(true);
    const [x, setX] = useState(0);
    const [maxX, setMaxX] = useState(200 * providers?.length)
    const slider = useRef();
    const navigate = useNavigate();

    const goLeft = () => {
        if (x - 1080 >= 0) {
          slider.current.scrollTo(x - 1080, 0);
        } else {
          slider.current.scrollTo(0, 0);
        }
    }

    const goRight = () => {
        if (x + 1080 <= maxX) {
          slider.current.scrollTo(x + 1080, 0);
        } else {
          slider.current.scrollTo(maxX, 0);
        }
    }

    const onSlide = () => {
        let temp = (200*providers?.length) - slider.current.offsetWidth - 20;
        setMaxX(temp)

        setX(slider.current.scrollLeft)
        setArrowLeft(slider.current.scrollLeft > 0 ? true : false)
        setArrowRight(slider.current.scrollLeft < maxX  ? true : false)
    }

    return(
        <>
        <HeaderPicks text="Providers" scroller={true} notMore={true} goLeft={goLeft} goRight={goRight} arrowLeft={arrowLeft} arrowRight={arrowRight}/>
        
        <div ref={slider} onScroll={()=> onSlide()} className="flex-providers">
            {providers && providers.map(p =>
                <div onClick={() => navigate('/play/group/'+p.name.toLowerCase().replaceAll(' ', '-'))} key={p.id}>
                    <img src={p.img} alt="provider" />
                </div>
            )}
        </div>
        </>
       
    )
}

const providers=[
    {id: 0, img: '/static/providers/0.png', name: 'Pragmatic Play'},
    {id: 1, img: '/static/providers/1.png', name: 'BGaming'},
    {id: 2, img: '/static/providers/2.png', name: 'Twist'},
    {id: 3, img: '/static/providers/3.png', name: 'Titan Gaming'},
    {id: 4, img: '/static/providers/4.png', name: 'Avatar UX'},
    {id: 5, img: '/static/providers/5.png', name: 'Popiplay'},
    {id: 6, img: '/static/providers/6.png', name: 'Belatra Games'},
    {id: 7, img: '/static/providers/7.png', name: 'Elk'},
    {id: 8, img: '/static/providers/0.png', name: 'BackSeat Gaming'},
    {id: 9, img: '/static/providers/1.png', name: 'BGaming1'},
    {id: 10, img: '/static/providers/2.png', name: 'Twist1'},
    {id: 11, img: '/static/providers/3.png', name: 'Titan Gaming1'},
    {id: 12, img: '/static/providers/4.png', name: 'Avatar UX1'},
    {id: 13, img: '/static/providers/5.png', name: 'Popiplay1'},
    {id: 14, img: '/static/providers/6.png', name: 'Belatra Games1'},
    {id: 15, img: '/static/providers/7.png', name: 'Elk1'},
]