import axios from 'axios';
import { store } from './store';

const axiosInstance = axios.create({
    baseURL: 'https://mtechback.com/api/'
})

axiosInstance.interceptors.request.use((config) => {
    const token = store.getState().user.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

export default axiosInstance;