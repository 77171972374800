import {useState, useEffect, useRef, useCallback} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { OutlinedInput, InputAdornment, FormControl, Popper, Typography, Chip} from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import AutoPopularGames from './AutoPopularGames';
import { addSearchHistory, removeSearchHistory } from '../../redux/reducers/PageReducer';
import {Container} from 'react-bootstrap';

function sleep(duration) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
}

export default function AutoCompleteSearch(){
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [searchOptions, setSearchOptions] = useState([]);
    
    const open = Boolean(anchorEl);
    const textRef = useRef();
    const popper = useRef();

    const {searchHistory} = useSelector(state => state.page);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
        if(open){
            (async () => {
                await sleep(1e3); // For demo purposes.
        
                setOptions([...topGames]);
            })();
        }
    }, [open]);

    const clearSearch = () => {
        setSearch('');
        handleClose();
    }
    
    const focused = () => {
        if(!anchorEl){
            setAnchorEl(textRef.current)
        }
    }

    const handleClickOutside = useCallback(
      (event) => {
        if (popper.current && !popper.current.contains(event.target) && textRef.current && !textRef.current.contains(event.target)) {
            handleClose();
        }
      },
      [popper],
    )

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const doSearch = (value) => {
        setSearch(value);
        if(value.length >= 3){
            let filtered = [...topGames];
            filtered = filtered.filter(x => x.title.toLowerCase().includes(value.toLowerCase()))
            setSearchOptions(filtered)
            if(filtered.length > 0){
                let index = searchHistory?.findIndex(x => x.name.toLowerCase().includes(value.toLowerCase()));
                if(index === -1){
                    dispatch(addSearchHistory({id: searchHistory.length, name: filtered[0].title}))
                }
            }
        }
    }

    const handleDeleteChip = (name) => {
        dispatch(removeSearchHistory(name))
    }

    
    return(
        <>
            <FormControl sx={{zIndex: '10', position:'relative'}} ref={textRef} fullWidth >
                <OutlinedInput
                    sx={{zIndex: '10', position:'relative', marginBottom: '.5rem'}}
                    onFocus={() => {focused()}}
                    className="search_home"
                    startAdornment={<InputAdornment  position="start"><SearchIcon className="search_icon"/></InputAdornment>}
                    endAdornment={<InputAdornment position="start">{search !== '' ? <CloseIcon onClick={() => {clearSearch()}} className="close_icon"/> : <></>}</InputAdornment>}
                    placeholder="Search games"
                    value={search}
                    onChange={(event) => {
                        doSearch(event.target.value);
                    }}
                />
            </FormControl>
            <Container style={{position: 'relative'}}>
                <Popper 
                ref={popper}
                placement="bottom"
                sx={{
                    maxHeight: '20rem',
                    minHeight: '20rem',
                    overflowY: 'scroll',
                    maxWidth: '100%',
                    width: '100%',
                    overflowX: 'hidden',
                    backgroundColor: '#1f161f',
                    borderRadius: '20px',
                    paddingBottom: '1rem',
                    zIndex: '10000'
                }}
                open={open}
                    anchorEl={anchorEl}
                    disablePortal={true}
                    modifiers={[
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                    },
                ]}>
                    {search.length < 3 &&
                    <>
                        <div className="display_search_autocomplete">
                            Search requires at least 3 characters.
                        </div>
                        
                        {searchHistory.length > 0 &&
                            <>
                                <Typography className="shadowed_text title_autocomplete" sx={{paddingTop: '1rem', paddingLeft: '1rem'}}>Recent Searches</Typography>
                                <div className="chip_container" style={{paddingTop: '1rem', paddingLeft: '1rem'}}>
                                    {searchHistory && searchHistory.map(s => 
                                        <Chip key={s.id} label={s.name} onDelete={() => handleDeleteChip(s.name)} />
                                    )}
                                </div>
                            </>
                        }
                        
                        <AutoPopularGames options={options}/>
                    </>
                    }

                    {search.length >= 3 &&
                    <>
                        {searchOptions.length === 0 &&
                        <>
                            <div className="display_search_autocomplete">
                            No results found.
                            </div>
                            <AutoPopularGames options={options}/>
                        </>
                        }
                        {
                            searchOptions.length > 0 &&

                            <AutoPopularGames options={searchOptions} search={true}/>
                        }
                    </>
                    }
                </Popper>
            </Container>
        </>
      
    )
}

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topGames = [
    { id: 1,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
    { id: 2,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
    { id: 3,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
    { id: 4,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
    { id: 5,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
    { id: 6,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
    { id: 7,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
    { id: 8,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
    { id: 9,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
    { id: 10,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
    { id: 11,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
    { id: 12,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
    
  ];