
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Paper from '@mui/material/Paper';
import {useState, useEffect} from 'react';
import axiosInstance from '../../redux/axios';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#362B38',
    ...theme.typography.body2,
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    borderRadius: '8px'
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 27,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#403642',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#521c688e',
        },
      },
    [`& .${stepConnectorClasses.line}`]: {
      height: 5,
      border: 0,
      backgroundColor: '#403642',
      borderRadius: 1,
      marginLeft: '1rem',
      marginRight: '1rem'
    },
}));


const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: '#403642',
    zIndex: 1,
    color: '#fff',
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor:
        '#923fb58e',
      boxShadow: '0 4px 10px 0 rgba(255,255,255,.2)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
        '#521c688e',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {props.icon && 
            <img alt="rank" style={{width: '100%', padding: '.2rem'}} src={props.icon} />
        }
      </ColorlibStepIconRoot>
    );
}
  
ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const ranks = [
    {id: 0, name: 'Herald', img: '/static/ranks/herald.png', color: '#4c6f51', wagerAmount: '$10k',
    benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]
    },
    {id: 1, name: 'Guardian', img: '/static/ranks/guardian.png', color: '#6f4c4c', wagerAmount: '$50k - $100k',
    benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 2, name: 'Crusader', img: '/static/ranks/crusader.png', color: '#00806a9e', wagerAmount: '$250k - $1M',
    benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 3, name: 'Legend', img: '/static/ranks/legend.png', color: '#bd9e47', wagerAmount: '$1M - $2.5M',
    benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 4, name: 'Ancient', img: '/static/ranks/ancient.png', color: '#498bbacf', wagerAmount: '$2.5M - $3M', benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 5, name: 'Divine', img: '/static/ranks/divine.png', color: '#9e52b1c2', wagerAmount: '$3M - $6M',benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 6, name: 'Immortal', img: '/static/ranks/immortal.png', color: '#de3b3bdb', wagerAmount: '$6M',benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]},
    {id: 7, name: 'Immortal I - C', img: '/static/ranks/immotral_1.png', color: 'rgb(220 57 20 / 76%)', wagerAmount: 'Top 100 $6M +',benefits: [
        {id:0, name: 'Bonus from Support in currency of your choice'},
        {id:1, name: 'Rakeback enabled'},
        {id:2, name: 'Weekly bonuses'}
    ]}
]

export default function StepperRankClub(){
    const rank = 'Herald';
    const [indexRank, setIndexRank] = useState(-1);
    const [ranks, setRanks] = useState([]);
    console.log(indexRank)
    useEffect(() => {
        async function getRanks() {
            try{
                const result = await axiosInstance.get('ranks')
                console.log(result)
                if(result?.data?.data){
                    setRanks(result?.data?.data)
                    let id = result?.data?.data.findIndex(x => {return x.name.toLowerCase() === rank.toLowerCase()});
                   
                    if(id !== -1){
                        setIndexRank(id + 1);
                    }
                }
            }catch{}
        }
        getRanks()
    }, [])


    const setMouseDown = () => {
        const el = document.getElementById('steper_desc_rank1')
        if(el){
            el.style.scrollSnapType = "none"
        }
    }
    const setMouseUp = () => {
        const el = document.getElementById('steper_desc_rank1')
        if(el){
            el.style.scrollSnapType = "y mandatory"
        }
    }
    return(
        <>
              <Stepper className="stepper_main_rankclub" alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
                    {ranks && ranks.map((r) => (
                        <Step key={r.id}>
                            <StepLabel StepIconComponent={() => ColorlibStepIcon({active: indexRank === r.id, completed: indexRank > r.id, className: '', icon: r.icon})}>
                                <Item id="steper_desc_rank1" onMouseDown={() => setMouseDown()} onMouseUp={() => setMouseUp()} className="steper_desc_rank">
                                    <h6 style={{backgroundColor: r.color}} className="h6_stepper">{r.name}</h6>
                                    <div className="stepper_wager_rank">
                                        <div>{r.wager_label}</div>
                                        <div>Wager Amount</div>
                                    </div>
                                    <div className="stepper_ul_rank">
                                        <ul>
                                            {r.characteristics && r.characteristics.map((b,index) =>
                                                <li key={index}>
                                                  <CheckCircleIcon style={{color: r.color}} className="li_icon_stepper"/> {b}
                                                </li>
                                            )}   
                                        </ul>
                                    </div>
                                </Item>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
        </>
    )
}