import { Typography } from "@mui/material"

export default function AutoPopularGames({options, search}){
    return(
        <>
            {!search &&
            <Typography className="shadowed_text title_autocomplete" sx={{paddingTop: '1rem', paddingLeft: '1rem'}}>Popular</Typography>
            }
            <div className="row_autocomplete">
                {options && options.map(p =>
                    <div key={p.id} className="col_autocomplete">
                        <img className="img_autocomplete" alt={p.title} src={p.img} />
                    </div>
                )}
            </div>
        </>
    )
}