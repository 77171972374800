import { Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Fragment } from 'react';

export default function FAQDetails({selected, menuItems}){
    const [expanded, changeExptended] = useState('');
    return(
        <div className="transaction_sidemenu_table" style={{gap: '.6rem'}}>
            {menuItems.map(m =>
                <Fragment key={m.id}>
                    {m.questions && m.questions.map(r => 
                    <Accordion key={r.id} className="accordion_rank"expanded={expanded === r.question} onChange={() => {r.question === expanded ? changeExptended(-1) : changeExptended(r.question)}}>
                            <AccordionSummary
                                className="accordion_rank_summary"
                                expandIcon={<ChevronLeftIcon />}
                                aria-controls={"panel"+r.id+"verify-content"}
                                id={"panel"+r.id+"verify-content"}
                            >
                                <Typography sx={{ flexShrink: 0, display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <span className="pt-1 question_faq">{r.question}</span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="rank_acc_details">
                                <div className="answer_faq" dangerouslySetInnerHTML={{__html: r.answer}}></div>
                            </AccordionDetails>
                    </Accordion>
                    )}
                </Fragment>
            )}
            
        </div>
    )
}