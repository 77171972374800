import QueryStatsIcon from '@mui/icons-material/QueryStats';

export default function TransactionDetails({menuItems, selected, steps, activeStep, setActiveStep}){
    return(
        <div className="transaction_sidemenu_table">
            <div className="display_spacebetween">
                <div className="display_spacebetween chashier_steps">
                {steps && steps.map(s =>
                    <div onClick={() => {setActiveStep(s.id)}} className={activeStep === s.id ? 'active_cashier_step' : ''} key={s.id}>{s.name}</div>
                )}
                </div>
            </div>
            <div className="main_container_transactions">
                <div>
                    <QueryStatsIcon className="icon_transactions"/>
                    <div className="pt-2">No {selected !== 2 ? steps[activeStep].name + ' ' + menuItems[selected].name : 'entries'}</div>
                </div>
            </div>
        </div>
    )
}